<template>
  <CoursePage :course="course">
    <template #content>
      <div v-if="course.faq">
        <div v-if="course.faq.length" id="faq" class="course-page__section">
          <h3 class="course-page__section-header">
            {{ $t("common.faq") }}
          </h3>

          <div class="course-page__section-content">
            <accordion>
              <accordion-item v-for="(qa, qi) in faq" :key="qi">
                <template #accordion-trigger>
                  <div class="accordion__header">
                    <h4>{{ qa.header }}</h4>
                  </div>
                </template>
                <template #accordion-content>
                  <ul v-if="qa.answers.length">
                    <li v-for="answer in qa.answers" v-html="answer" />
                  </ul>
                </template>
              </accordion-item>
            </accordion>
          </div>
        </div>

        <div v-else class="course-page__section-error">
          {{ $t("common.faq_empty") }}
        </div>
      </div>
    </template>
  </CoursePage>
</template>
<script setup>
import CoursePage from "@/views/pages/CoursePage.vue";
import { computed, onMounted } from "vue";
import { useProductsStore } from "@/store/modules/products.store";
import Accordion from "@/components/accordion/Accordion.vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import { splitFaqHtmlByTags } from "@/util/helpers";
import { useRoute } from "vue-router";
import useIsMobile from "@/util/useIsMobile";

const route = useRoute();
const { checkWidth } = useIsMobile();

const productsStore = useProductsStore();

const course = computed(() => {
  return productsStore.current || {};
});

const faq = computed(() => {
  if (
    !course.value.faq ||
    !course.value.faq[0] ||
    !("answer" in course.value.faq[0])
  ) {
    return null;
  }

  return splitFaqHtmlByTags(course.value.faq[0].answer);
});

onMounted(() => {
  productsStore.requestCurrent(route.params.id);
  checkWidth();
});
</script>
