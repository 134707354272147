<template>
  <Form>
    <div class="search-wrap">
      <img src="../../assets/icons/search-gray.svg" alt="search" />
      <input
        v-model="searchValue"
        name="q"
        :placeholder="$t('navigation.search_placeholder')"
        @input="debouncedSearch"
      />
      <button
        class="cancel"
        @click="clearSearch"
        v-text="$t('common.cancel')"
      />
    </div>
  </Form>
</template>

<script>
import { Form } from "vee-validate";
import debounce from "lodash.debounce";
import { useRoute } from "vue-router";

export default {
  components: { Form },
  props: {
    routeName: String,
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  data() {
    return {
      searchValue: this.route.query.q,
    };
  },
  created() {
    this.debouncedSearch = debounce(this.searchCourses, 500);
  },
  methods: {
    searchCourses() {
      this.$router.replace({
        name: this.routeName,
        query: { q: this.searchValue },
      });
    },
    clearSearch() {
      this.searchValue = "";
      this.$router.replace({
        name: this.routeName,
      });
      this.$emit("disable:search");
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrap {
  position: relative;
  display: flex;
  align-items: center;

  img {
    position: absolute;
    left: 15px;
  }
  button {
    position: absolute;
    right: 4px;
    height: 40px;
    width: 40px;
    background-color: transparent;
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  input {
    border: 1px solid #dcdee9;
    border-radius: 24px;
    width: 100%;
    padding: 14px 40px;
  }

  .cancel {
    color: $color-menu-item;
    font-size: $font-size-xs;
    font-family: $font-family;
    padding-right: 20px;
  }
}
</style>
