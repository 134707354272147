<template>
  <div v-if="cartStore.products.length" class="cart-block">
    <div class="cart-block__info-block">
      <p class="cart-block__price">{{ cartStore.totalCount }} €</p>
      <p class="cart-block__total">
        {{ $t("common.courses_count", { count: cartStore.products.length }) }}
      </p>
    </div>
    <div class="cart-block__products-wrapper">
      <div v-if="cartStore.products.length < 3" class="cart-block__products">
        <img
          v-for="course in cartStore.products"
          :key="course.id"
          :src="course.thumb"
        />
      </div>
      <div v-else class="cart-block__carousel">
        <ShoppingCartList>
          <template #body>
            <swiper
              :loop="false"
              :slides-per-view="swiperOptions.slidesPerView"
              :space-between="swiperOptions.spaceBetween"
            >
              <Loading v-if="cartStore.loading" />
              <swiper-slide
                v-for="course in cartStore.products"
                :key="course.id"
              >
                <img :src="course.thumb" />
              </swiper-slide>
            </swiper>
          </template>
        </ShoppingCartList>
      </div>
      <router-link class="cart-block__cart-button" :to="{ name: 'cart' }">
        <img src="../../assets/menu/cart-white.svg" alt="cart" />
      </router-link>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/Loading.vue";
import { useCartStore } from "@/store";
import { useRouter } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import ShoppingCartList from "@/components/cart/ShoppingCartList.vue";

export default {
  components: {
    ShoppingCartList,
    Swiper,
    SwiperSlide,
    Loading,
  },
  setup() {
    const INITIAL_CAROUSEL_WIDTH = 50;

    const cartStore = useCartStore();
    const router = useRouter();

    return {
      cartStore,
      router,
      INITIAL_CAROUSEL_WIDTH,
    };
  },
  data() {
    return {
      courses: [],
      showDiscountForm: false,
      swiperOptions: {
        spaceBetween: 1,
        slidesPerView: 2.5,
      },
    };
  },
};
</script>
<style lang="scss">
.cart-block {
  position: sticky;
  border-top: 1px solid $color-light-grey;
  z-index: 50;
  bottom: 0;
  background-color: $color-white;
  padding: 20px;
  display: flex;
  align-items: center;

  @media (min-width: 900px) {
    display: none;
  }

  &__info-block {
    padding-right: 40px;
  }

  &__price {
    font-size: $font-size-lg;
    line-height: 22px;
    font-weight: 500;
    font-family: $font-family;
    color: $color-blue;
    width: max-content;
  }

  &__total {
    font-size: $font-size-xs;
    line-height: 22px;
    font-weight: 400;
    font-family: $font-family;
    color: $color-menu-item;
  }

  &__products {
    display: flex;
    gap: 10px;
    margin-right: 10px;

    img {
      width: 54px;
      height: 54px;
      object-fit: cover;
    }
  }

  &__carousel {
    max-width: 150px;
    text-align: right;
    width: 100%;

    img {
      width: 54px;
      height: 54px;
      object-fit: cover;
    }
  }

  &__products-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  &__cart-button {
    background-color: $color-primary;
    border-radius: 8px;
    padding: 12px;
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
