<template>
  <div>
    <!-- <SimpleNavigation :auth="true"/> -->
    <Navigation />
    <HeaderBlock />

    <div class="bg-grey">
      <div class="container">
        <div class="courses">
          <div class="courses__header">
            <div class="courses__column courses__column--switch">
              <h3>{{ $t("common.favourites") }}</h3>
            </div>
            <div class="courses__header-column"></div>
          </div>

          <div class="courses__wrap">
            <template v-for="course in courses" :key="course.id">
              <div class="course">
                <router-link
                  :to="{
                    name: ROUTE.Course.About,
                    params: {
                      id: course.id,
                      backRoute: ROUTE.Dashboard.Favourites,
                    },
                  }"
                >
                  {{ course.title }}
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script setup>
import HeaderBlock from "@/components/dashboard/HeaderBlock";
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation.vue";
import { computed } from "vue";
import { ROUTE } from "@/router/routenames";

const courses = computed(() => {
  const str = localStorage.getItem("favoriteCourses");
  if (str) {
    return Object.values(JSON.parse(str));
  }
});
</script>
<style lang="scss" scoped>
.courses {
  padding-top: 60px;
  padding-bottom: 80px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 27px;
    @media screen and (max-width: 900px) {
      width: 100%;
    }

    h3 {
      color: $color-black;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 900px) {
      // flex-direction: column;
    }
  }

  &__settings-label {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__column {
    display: flex;
    align-items: center;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &--switch {
      gap: 40px;
      @media screen and (max-width: 900px) {
        gap: 10px;
        width: 100%;
        align-items: flex-start;
      }
    }
  }

  &__wrap {
    overflow-x: auto;

    font-family: $font-family;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .course {
      padding: 13px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: inset 0 -1px 0 0 #dcdee9;
      width: 100%;
      min-width: 100%;
    }
  }
}
</style>
