<template>
  <div class="progress" :class="{ 'progress--active': active }">
    <div class="progress__inner-text">{{ innerCurrent }} / {{ max }}</div>
    <div class="progress__inner" :style="{ right: percent + '%' }"></div>
  </div>
</template>
<script>
export default {
  props: {
    active: {},
    max: {
      default: 30,
    },
    current: {
      default: 15,
    },
  },
  data() {
    return {
      innerCurrent: 0,
    };
  },
  computed: {
    percent() {
      var percent = (this.innerCurrent / this.max) * 100;
      return 100 - percent;
    },
  },
  created() {
    this.innerCurrent = this.current;

    // setInterval(this.update, 100);
  },
  methods: {
    update() {
      this.innerCurrent++;
      if (this.innerCurrent === this.max) {
        this.innerCurrent = 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.progress {
  height: 34px;
  width: 88px;
  border-radius: 17px 17px 17px 17px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: $font-family;
  font-size: $font-size-sm;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  overflow: hidden;

  &--active {
    background-color: $color-light-blue;
    .progress__inner-text {
      color: black;
    }
  }

  &__inner-text {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    height: 34px;
    width: 100%;
    border-radius: 17px 17px 17px 17px;
    z-index: 2;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    height: 34px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    background-color: $color-green;
  }
}
</style>
