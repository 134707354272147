<template>
  <div class="material">
    <div class="material__wrap">
      <div
        v-if="material"
        class="material__item"
        :class="{ 'material__item--done': material.completed }"
      >
        <div class="material__name">
          <div v-if="isCompletionAutomatic" class="material__status">
            <img src="../../assets/correct_white.svg" alt="checkbox" />
          </div>
          <div
            v-if="isCompletionManual"
            class="material__status material__set-completed"
          >
            <img
              src="../../assets/correct_white.svg"
              alt="checkbox"
              @click="setCompleted"
            />
          </div>
          <div class="material__title">
            <div v-dompurify-html="material.title" />
          </div>
        </div>
        <div v-if="readonly" class="material__description">
          <div v-if="hasTimer" class="material__time">
            <img src="../../assets/course/course_clock.svg" alt="clock" />
            {{ material.duration }}
          </div>
          <a
            v-if="material.dynamic_form"
            class="material__video"
            :href="material.dynamic_form"
            target="_blank"
          >
            <img
              src="../../assets/course/dynamicform-gray.svg"
              alt="dynamic form"
            />
            {{ $t("common.situation_material") }}
          </a>
          <div
            v-if="material.video"
            class="material__video"
            @click.prevent="onVideo"
          >
            <img src="../../assets/course/course_video.svg" alt="video" />
            {{ $t("common.video_material") }}
          </div>
          <a
            v-if="material.document"
            class="material__activity"
            :href="material.document"
            target="_blank"
          >
            <img src="../../assets/course/document-gray.svg" alt="document" />
            {{ $t("common.document_material") }}
          </a>
          <a
            v-if="material.document_video"
            class="material__activity"
            :href="material.document_video"
            target="_blank"
          >
            <img
              src="../../assets/course/course_video.svg"
              alt="course video"
            />
            {{ $t("common.video_material") }}
          </a>
          <a
            v-if="material.algorithm"
            :href="material.algorithm"
            class="material__activity"
            target="_blank"
          >
            <img
              src="../../assets/course/hlalgorithm-gray.svg"
              alt="algorithm"
            />
            {{ $t("common.algorithm") }}
          </a>
          <a
            v-if="material.extlmsroom"
            class="material__activity"
            :href="material.extlmsroom"
            target="_blank"
          >
            <img
              src="../../assets/course/extlms-room-gray.svg"
              alt="extlms room"
            />
            {{ $t("common.extlms_room_material") }}
          </a>
          <a
            v-if="material.survey"
            :href="material.survey"
            class="material__activity"
            @click.prevent="viewActivity(material.survey, material.title)"
          >
            <img
              src="../../assets/course/questionnaire-gray.svg"
              alt="questionnaire"
            />
            {{ $t("common.questionnaire") }}
          </a>
          <a
            v-if="material.quiz"
            :href="material.quiz"
            class="material__activity"
            @click.prevent="viewActivity(material.quiz, material.title)"
          >
            <img src="../../assets/course/quiz-gray.svg" alt="quiz" />
            {{ $t("common.quiz") }}
          </a>
          <a
            v-if="material.forum"
            :href="material.forum"
            class="material__activity"
            @click.prevent="viewActivity(material.forum, material.title)"
          >
            <img src="../../assets/course/forum-gray.svg" alt="forum" />
            {{ $t("common.forum") }}
          </a>
          <a
            v-if="material.hvp"
            :href="material.hvp"
            class="material__activity"
            @click.prevent="viewActivity(material.hvp, material.title)"
          >
            <img src="../../assets/course/hvp-gray.svg" alt="hvp" />
            {{ $t("common.hvp") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, getCurrentInstance } from "vue";
import {
  AUTO_COMPLETION,
  MANUAL_COMPLETION,
} from "@/store/modules/completions.store";
import router from "@/router";
import { ROUTE } from "@/router/routenames";
import { isShoppingEnabled } from "@/util/helpers";

const NO_TIMER_ACTIVITIES = [
  "questionnaire",
  ...(!isShoppingEnabled() ? ["extlmsroom"] : []),
];

const emit = defineEmits(["set:completion"]);
const props = defineProps({ material: Object });

const vm = getCurrentInstance();

const isCompletionManual = computed(
  () => props.material.completion === MANUAL_COMPLETION,
);
const isCompletionAutomatic = computed(
  () => props.material.completion === AUTO_COMPLETION,
);
const readonly = computed(() => props.material.readonly === false);
const hasTimer = computed(
  () => !NO_TIMER_ACTIVITIES.includes(props.material.module),
);

const onVideo = () => {
  /** @type {import('mitt').Emitter} */
  const emitter = vm.appContext.config.globalProperties.emitter;

  emitter.emit("video", {
    description: props.material.description,
    video: props.material.video,
  });
};

const setCompleted = () => {
  emit("set:completion", props.material);
};

const viewActivity = (url, title) =>
  router.push({
    name: ROUTE.Course.Activity,
    query: {
      json: JSON.stringify({ url, title, backUrl: window.location.href }),
    },
  });
</script>

<style lang="scss" scoped>
.material {
  box-shadow: inset 0 -1px 0 0 #dcdee9;

  &:last-child {
    box-shadow: none;
  }

  &__set-completed {
    cursor: pointer;
  }

  &__item {
    min-height: 70px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px 0;
    align-items: center;
    justify-content: space-between;
    word-break: break-word;

    @media screen and (max-width: 1200px) and (min-width: 900px) {
      padding: 10px 0;
    }
    @media screen and (max-width: 768px) {
      padding: 10px 0;
    }

    &--done {
      .material__status {
        background: $color-green;
      }
    }
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__status {
    height: 24px;
    width: 24px;
    background-color: rgba($color-black, 0.4);
    border-radius: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 24px;
    min-height: 24px;

    img {
    }
  }

  &__title {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__description {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 1200px) and (min-width: 900px) {
      flex-direction: column;
    }
    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
  }

  &__video,
  &__activity {
    cursor: pointer;
    justify-content: flex-start;

    &:hover {
      background-color: darken($color-light-blue, 3);
    }
  }

  &__time {
    justify-content: center;
  }

  &__time,
  &__video,
  &__activity {
    display: flex;
    align-items: center;
    height: 34px;
    border-radius: 17px;
    background-color: $color-light-blue;

    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 18px;

    padding: 5px 16px;
    gap: 10px;
    width: 162px;
    text-decoration: none;
  }
}
</style>
