<template>
  <div>
    <Navigation />
    <PageHeader
      :image="header?.image"
      :title="header?.title"
      :description="header?.description"
    />
    <HeaderBlock />

    <div>
      <div class="container">
        <CourseList
          :courses="purchasedStore.filtered"
          :title="$t('common.my_courses')"
          :route-name="ROUTE.Dashboard.Courses"
          @filter:courses="filterCourses"
        />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import HeaderBlock from "@/components/dashboard/HeaderBlock";
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation.vue";
import { useRoute } from "vue-router";
import { getPurchasedStore } from "@/store/modules/purchased.store";
import CourseList from "@/components/CourseList.vue";
import { ROUTE } from "@/router/routenames";
import PageHeader from "@/components/PageHeader.vue";
import { usePageStore } from "@/store/modules/page.store";

export default {
  components: {
    PageHeader,
    CourseList,
    HeaderBlock,
    Footer,
    Navigation,
  },
  setup() {
    const route = useRoute();
    const purchasedStore = getPurchasedStore();
    const pagesStore = usePageStore();

    return {
      route,
      purchasedStore,
      pagesStore,
    };
  },
  data() {
    return {
      header: null,
    };
  },
  computed: {
    ROUTE() {
      return ROUTE;
    },
  },
  watch: {
    "i18n.global.locale": {
      immediate: true,
      async handler() {
        const { id } = await this.pagesStore.book("dashboard");
        this.header = await this.pagesStore.request(id);
      },
    },
  },
  methods: {
    filterCourses(query) {
      this.purchasedStore.getCourses(query);
    },
  },
};
</script>

<style lang="scss" scoped></style>
