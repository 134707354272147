<template>
  <div v-show="show" class="covid-alert">
    <div class="container">
      <div class="covid-alert__wrap">
        <div></div>
        <div v-dompurify-html="$t('covid_message', { to })" class="content" />
        <a href="#" @click="cancel">
          <img src="../assets/cancel/cancel.svg" alt="cancel" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { isShoppingEnabled } from "@/util/helpers";

export default {
  data() {
    return {
      covidWarning: localStorage.getItem("covid_warning") === null,
      to: process.env.VUE_APP_OLD_APP,
    };
  },
  computed: {
    show() {
      return isShoppingEnabled() && this.covidWarning;
    },
  },
  created() {
    this.covidWarning = localStorage.getItem("covid_warning") === null;
  },
  methods: {
    cancel() {
      localStorage.setItem("covid_warning", true);
      this.covidWarning = false;
    },
  },
};
</script>

<style lang="scss">
.covid-alert {
  color: $color-black;
  font-family: $font-family;
  font-size: $font-size-sm;
  letter-spacing: 0;
  line-height: 8px;
  text-align: center;
  background-color: #f3ac00;
  padding: 19px;
  @media screen and (max-width: 900px) {
    line-height: 20px;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: $color-black;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
