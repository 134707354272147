<template>
  <div class="course-product__container">
    <div class="course-product">
      <p class="course-product__label">
        {{ $t("course.events", { n: products.length }) }}
      </p>
      <ul class="course-product__list">
        <li
          v-for="(product, index) in sortedProducts"
          :key="product.product_id"
        >
          <button
            class="course-product__item"
            :class="{
              'course-product__item--active': selected === product.product_id,
            }"
            type="button"
            @click="select(product)"
          >
            <span class="course-product__item-content">
              <span class="course-product__item-name">
                {{ product.location_name }}
              </span>
              <span v-if="product.city" class="course-product__item-address">
                {{ `${product.city}, ${product.location_address}` }}
              </span>
              <span v-else class="course-product__item-address">
                {{ product.location_address }}
              </span>
              <div class="course-product__price-block">
                <span
                  v-if="product.special"
                  class="course-product__item-special"
                >
                  {{ product.price_formated }}
                </span>
                <span class="course-product__item-price">
                  <template v-if="product.special">{{
                    product.special.price_formated
                  }}</template>
                  <template v-else>{{ product.price_formated }}</template>
                </span>
              </div>
            </span>
            <span class="course-product__item-start-label">
              {{ $t("course.start") }}
            </span>
            <span class="course-product__item-start-date">
              {{ getParsedDate(product.start_date) }}
            </span>
            <span class="course-product__item-end-label">
              {{ $t("course.end") }}
            </span>
            <span class="course-product__item-end-date">
              {{ getParsedDate(product.end_date) }}
            </span>
          </button>
          <hr v-if="index < products.length - 1" />
        </li>
      </ul>
    </div>
    <div :class="['course-product__add-to-cart', { selected }]">
      <CoursePrice
        v-if="isMobile && selectedProduct"
        :price="selectedProduct.price_formated"
        :special="selectedProduct.special?.price_formated"
      />
      <CustomButton
        full-width
        :type="selected ? 'dark' : 'default'"
        :disabled="!selected"
        :text="$t('common.add_to_cart')"
        class=""
        @click="addToCart"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";
import CustomButton from "../CustomButton.vue";
import moment from "moment";
import i18n from "@/i18n";
import { useCartStore } from "@/store";
import CoursePrice from "@/components/course/CoursePrice.vue";
import useIsMobile from "@/util/useIsMobile";

const cartStore = useCartStore();

const { isMobile } = useIsMobile();

const selected = ref(null);

const props = defineProps({
  products: Array,
});

const sortedProducts = computed(() => {
  const sortedProducts = props?.products?.slice();
  sortedProducts?.sort(
    (a, b) => new Date(a.start_date) - new Date(b.start_date),
  );
  return sortedProducts;
});

const selectedProduct = computed(() => {
  if (!selected.value) {
    return null;
  }

  return sortedProducts.value?.find((p) => p.product_id === selected.value);
});

const getParsedDate = (date) => {
  return moment(date).locale(i18n.global.locale).format("LL");
};

const select = (product) => {
  if (selected.value === product.product_id) {
    selected.value = null;
  } else {
    selected.value = product.product_id;
  }
};

const addToCart = () => {
  if (!selected.value) return;
  cartStore.add(selected.value);
};
</script>
<style lang="scss">
.course-product {
  margin-bottom: 20px;
  border: 1px solid $color-grey-border;
  &__container {
    margin-bottom: 20px;
  }
  &__label {
    font-family: $font-family;
    color: $color-cadet-blue-crayola;
    font-size: $font-size-sm;
    font-weight: 400;
    padding-top: 23px;
    padding-left: 20px;
    padding-bottom: 10px;
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    & > li {
      display: block;
      & > hr {
        margin: 0;
        margin-left: 20px;
        margin-right: 20px;
        border: 1px solid $color-grey-border;
        border-top: none;
      }
    }
  }

  &__price-block {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__item {
    display: grid;
    width: 100%;
    font-family: $font-family;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    padding: 20px;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "content content"
      "start-label end-label"
      "start-date end-date";

    &--active {
      background-color: $color-primary;
      color: $color-white;

      .course-product__item-content,
      .course-product__item-name,
      .course-product__item-address,
      .course-product__item-start-label,
      .course-product__item-start-date,
      .course-product__item-end-label,
      .course-product__item-price,
      .course-product__item-end-date {
        color: $color-white;
      }
    }

    &-content {
      grid-area: content;
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "name special"
        "address price";
      align-items: center;
      margin-bottom: 4px;
      padding-bottom: 20px;
    }

    &-name {
      grid-area: name;
      font-weight: 500;
      color: $color-black;
      font-family: $font-family;
      font-size: $font-size-md;
    }

    &-special {
      grid-area: special;
      color: $color-light-coral;
      text-decoration: line-through;
      font-weight: 500;
      font-size: $font-size-md;
    }

    &-price {
      grid-area: price;
      font-size: $font-size-lg;
      color: $color-primary;
      font-weight: 500;
    }

    &-address {
      grid-area: address;
      color: $color-black;
      font-size: $font-size-md;
      font-weight: 400;
    }

    &-start-label {
      grid-area: start-label;
      color: $color-grey;
      font-size: $font-size-md;
      font-weight: 400;
    }

    &-start-date {
      grid-area: start-date;
      color: $color-black;
      font-size: $font-size-md;
      font-weight: 400;
    }

    &-end-label {
      grid-area: end-label;
      color: $color-grey;
      font-size: $font-size-md;
      font-weight: 400;
    }

    &-end-date {
      grid-area: end-date;
      color: $color-black;
      font-size: $font-size-md;
      font-weight: 400;
    }
  }
  &__add-to-cart {
    &.selected {
      @media screen and (max-width: 900px) {
        position: fixed;
        bottom: 0;
        z-index: 20;
        display: flex;
        width: 100%;
        background-color: #fff;
        padding: 5px 10px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
