<template>
  <div class="courses">
    <div class="courses__header">
      <div class="courses__column courses__column--switch">
        <SearchForm
          v-if="isSearchEnabled"
          :route-name="routeName"
          @disable:search="disableSearch"
        />
        <div
          v-if="!isSearchEnabled"
          class="courses__column courses__column--switch__wrapper"
        >
          <h3 v-if="title" v-text="title" />
        </div>
        <a
          v-if="!isSearchEnabled"
          href="#"
          class="navbar__link navbar__link__right"
          @click="toggleSearch"
        >
          <img src="../assets/icons/search-gray.svg" alt="search" />
        </a>
      </div>
      <div class="courses__header-column"></div>
    </div>

    <div class="courses__wrap">
      <Loading v-if="loading" />
      <CourseListItem
        v-for="course in courses"
        :key="course.id"
        :course="course"
      />
    </div>
  </div>
</template>
<script>
import SearchForm from "@/components/search/SearchForm.vue";
import Loading from "@/components/Loading.vue";
import { useRoute } from "vue-router";
import CourseListItem from "@/components/course/CourseListItem.vue";

export default {
  components: {
    CourseListItem,
    SearchForm,
    Loading,
  },
  props: {
    routeName: { String, required: true },
    courses: { Array, default: [] },
    title: { String, default: "" },
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  data() {
    return {
      completed: false,
      loading: false,
      isSearchEnabled: false,
      searchValue: this.route.query.q,
    };
  },
  watch: {
    "route.query.q": {
      handler() {
        this.getCourses();
      },
    },
  },
  created() {
    this.getCourses();
  },
  methods: {
    toggleSearch(event) {
      event.preventDefault();
      this.isSearchEnabled = !this.isSearchEnabled;
    },
    disableSearch() {
      this.isSearchEnabled = false;
    },
    async getCourses() {
      if (this.route.query.q) {
        this.isSearchEnabled = true;
      }
      this.$emit("filter:courses", this.route.query.q);
    },
  },
};
</script>
<style lang="scss" scoped>
.courses {
  padding-top: 30px;
  padding-bottom: 80px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
    @media screen and (max-width: 900px) {
      width: 100%;
    }

    h3 {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 900px) {
      // flex-direction: column;
    }
  }

  &__settings-label {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__column {
    display: flex;
    align-items: center;

    &--switch {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: $breakpoint-lg) {
        align-items: flex-start;
      }

      &__wrapper {
        gap: 40px;
        @media screen and (max-width: $breakpoint-lg) {
          gap: 10px;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      form {
        width: 100%;
      }
    }
  }
}
</style>
