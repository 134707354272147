export default {
  "footer": {
    "hybridlab": {
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
      "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как это работает"])},
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])}
    },
    "private": {
      "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Э-магазин"])},
      "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как это работает"])}
    },
    "business": {
      "business_and_institutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможности для организаций"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка"])}
    }
  },
  "payment": {
    "montonio_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montonio"])},
    "pp_express": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal Express Checkout"])},
    "free_checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатное бронирование"])},
    "header_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата успешно произведена"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться на домашнюю страницу"])}
  },
  "common": {
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или"])},
    "birthday_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рождения"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранять"])},
    "paypal_redirection_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы будете перенаправлены в платежную систему PayPal, где сможете завершить оплату."])},
    "change_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить информацию"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменять"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["смени пароль"])},
    "login_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Или зарегистрируйтесь со своим логином"])},
    "login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключитесь к Facebook"])},
    "are_you_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас уже есть аккаунт?"])},
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["узнать больше"])},
    "company_types": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объем курса"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы заказать специализированное обучение для сотрудников вашей компании, предоставьте следующую информацию: количество сотрудников, желаемую тему обучения и объем или продолжительность обучения. \nЭто поможет нам связаться с вами и предоставить вам лучшее предложение, соответствующее вашим потребностям."])}
    },
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы"])},
    "subscription_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ внутреннего обучения"])},
    "subscription_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы заказать специализированное обучение для сотрудников вашей компании, предоставьте следующую информацию: количество сотрудников, желаемую тему обучения и объем или продолжительность обучения. \nЭто поможет нам связаться с вами и предоставить вам лучшее предложение, соответствующее вашим потребностям."])},
    "subscription": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы заинтересованы в подписке на курс или она не соответствует вашим потребностям, свяжитесь с нами."])}
    },
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата"])},
    "payment_checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершить оплату"])},
    "about_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О курсе"])},
    "by_completing_the_payment_you_agree_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Совершая оплату, вы соглашаетесь с"])},
    "terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условиями"])},
    "send_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить запрос"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политикой конфиденциальности"])},
    "cookie_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика использования файлов cookie"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощь"])},
    "old_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старая версия"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
    "faq_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Д.В.К."])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часто задаваемые вопросы"])},
    "request_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма запроса"])},
    "get_in_touch_easily_by_filling_out_the_contact_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легко свяжитесь с нами, заполнив контактную форму"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "hybridlab_contacts_and_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты и подробности HybridLab"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка"])},
    "upcoming_courses_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ближайших курсов нет."])},
    "upcoming_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ближайшие курсы"])},
    "lastest_solve_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние курсы для решения"])},
    "all_my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все мои курсы"])},
    "lastest_solve_courses_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недавних спринтерских курсов нет."])},
    "show_valid_certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать только действительные сертификаты"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["назад"])},
    "course_end_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат прохождения курса"])},
    "certificate_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертификат был выдан"])},
    "course_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Материал курса"])},
    "my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои курсы"])},
    "show_end_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать только пройденные курсы"])},
    "purchases_history_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет покупок"])},
    "order_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер заказа:"])},
    "watch_the_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть презентацию"])},
    "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для человека"])},
    "apply_a_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить код скидки"])},
    "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код скидки"])},
    "your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше имя"])},
    "your_firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше имя"])},
    "your_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша фамилия"])},
    "your_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш номер телефона"])},
    "your_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш адрес электронной почты"])},
    "your_birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша дата рождения"])},
    "your_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите свой пол"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбирать"])},
    "select_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тему"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])},
    "looking_course_for_bigger_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заинтересованы в курсах для больших групп?"])},
    "faq_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часто задаваемых вопросов нет."])},
    "get_in_touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться"])},
    "courses_for_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы для компаний и учреждений"])},
    "courses_for_business_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повышайте квалификацию сотрудников вашей компании или организации по утвержденной методике обучения HybridLab."])},
    "courses_by_business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы по типу учреждения"])},
    "in_this_subject_you_learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этой теме вы узнаете"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать больше"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр"])},
    "remind_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запомнить пароль"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["электронная почта \nпочтовый адрес"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли логин или пароль?"])},
    "reminder_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминание отправлено"])},
    "try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте еще раз"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условиями использования"])},
    "i_have_met_and_agree_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я прочитал и согласен с"])},
    "user_register_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш логин успешно создан"])},
    "you_already_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас уже есть аккаунт?"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "incorrect_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверное имя пользователя или пароль"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
    "login_to_learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединяйтесь к обучению"])},
    "or_login_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Или зарегистрируйтесь со своим логином"])},
    "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас еще нет аккаунта?"])},
    "create_new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте новый."])},
    "login_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти через Google"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
    "register_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес электронной почты"])},
    "register_error": {
      "username_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя уже занято"])},
      "email_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта уже занята"])},
      "password_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен быть не менее 8 символов"])}
    },
    "create_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Придумайте пароль"])},
    "repeat_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
    "interested_in_courses_for_larger_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заинтересованы в курсах для больших групп?"])},
    "fill_out_the_inquiry_form_and_we_will_contact_you_shortly_with_an_individual_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните форму запроса и мы свяжемся с вами в ближайшее время с индивидуальным предложением."])},
    "fill_out_the_request_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните форму запроса"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Делиться"])},
    "all_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все курсы"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделанный"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в корзину"])},
    "cart_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В корзине пусто"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корзина"])},
    "total_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая сумма оплаты"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cработало"])},
    "add_to_cart_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товар успешно добавлен"])},
    "add_to_cart_modal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс успешно добавлен в корзину"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотите продолжить покупки или перейти в корзину?"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить покупки"])},
      "go_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в Корзину"])}
    },
    "video_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["видео"])},
    "document_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
    "extlms_room_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ExtLMS камбарис"])},
    "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алгоритм"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контрольный опрос"])},
    "questionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкета"])},
    "forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форум"])},
    "hvp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интерактивный контент"])},
    "general_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая часть"])},
    "theoretical_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теоретический материал"])},
    "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тесты"])},
    "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертификат"])},
    "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Практические занятие"])},
    "in_the_sim_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В комнате для симуляции"])},
    "recieve_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После успешного завершения курса вы получите сертификат"])},
    "course_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало обучения"])},
    "course_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длительность курса"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в"])},
    "popular_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самые популярные курсы"])},
    "recommended_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуемые курсы для вас"])},
    "share_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться курсом"])},
    "share_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить по"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать"])},
    "certificate_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия сертификата"])},
    "connect_other_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключите второе устройство"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])},
    "purchases_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История покупки"])},
    "my_certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои сертификаты"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["привет"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для бизнеса"])},
    "change_profile_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить информацию профиля"])},
    "password_settings_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы напомним вам о курсах согласно установленному часовому поясу"])},
    "change_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pедактировать профиль"])},
    "change_account_information_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pедактировать"])},
    "no_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По этим критериям не удалось найти более одного курса."])},
    "save_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
    "repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
    "create_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать пароль"])},
    "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["электронная почта \nпочтовый адрес"])},
    "enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите новый пароль"])},
    "repeat_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите новый пароль"])},
    "situation_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ситуация"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город"])},
    "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часовой пояс"])},
    "preferred_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпочтительный язык"])},
    "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите город"])},
    "country_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите страна"])},
    "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите язык"])},
    "timezone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите часовой пояс"])},
    "organisation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название организации"])},
    "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название должности"])},
    "favourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избранное"])},
    "buy_as_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридическое лицо"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название компании"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название компании"])},
    "company_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код компании"])},
    "company_code_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите код компании"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес компании"])},
    "company_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес компании"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НДС-номер компании"])},
    "company_vat_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите НДС-номер компании (необязательно)"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупатель"])},
    "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
    "courses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " курсы"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сортировать по"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отмена"])}
  },
  "home": {
    "contactus": {
      "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться"])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона."])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставьте свой номер телефона и наша команда свяжется с вами в ближайшее время и расскажет обо всех вариантах."])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнайте больше о возможностях для учреждений и предприятий"])}
    },
    "advantages": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учимся делать мир лучше"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы создаем инновационные решения для обучения, которые легче освоить и к которым удобнее получить доступ."])},
      "types": [
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы по утвержденной методике обучения"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позволяет учиться и практиковаться в любом месте и в любое время, согласно вашему графику и потребностям"])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пройти сертификацию"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предоставляет возможность получить сертификаты и продемонстрировать ваши навыки и знания."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дистанционное обучение"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позволяет изучать дистанционно с помощью виртуальных симуляций, предоставляющих аутентичный и интерактивный опыт обучения."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повысить квалификацию сотрудников организации"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® помогает организациям совершенствовать знания и навыки своих сотрудников, обеспечивая высокую квалификацию и успешные результаты."])}
        }
      ]
    },
    "types": {
      "find-out-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["узнать больше"])}
    },
    "clients": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы сотрудничаем с более чем 20 известными компаниями."])}
    },
    "courses-by-category": {
      "show-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть курсы"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы по категориям"])}
    },
    "courses-by-filter": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы по фильтрам"])}
    }
  },
  "navigation": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "how-it-works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как это работает"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основной"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О HybridLab"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение пароля"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы"])},
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск курсов"])},
    "opportunities_for_institutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможности для учреждений"])},
    "projects": {
      "dropdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проекты ЕС"])},
      "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реализуемые"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реализованные"])}
    }
  },
  "commonm": {
    "back_to_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться на главную страницу"])}
  },
  "about": {
    "about_hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О ГибридЛабе"])},
    "header": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синтез дистанционного обучения и практического моделирования."])}
    },
    "map": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделать мир обучения лучше"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы создаем лучшие решения для обучения, которые более доступны и удобны."])},
      "items": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешествия"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешествуя, мы всегда искали, чего не хватает и что еще нужно сделать, создать и улучшить в обучающем мире."])}
        },
        "1": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня наша основная цель — обеспечить круглосуточное практическое моделирование и удаленную передачу технических и нетехнических навыков, особенно в области медицинского образования."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глобальная мысль"])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Именно поэтому мы разрабатываем и предлагаем инновационные методы обучения, преподавания, обучения и технологические решения."])}
        }
      }
    },
    "applies_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используется для развертывания в отдаленных районах с ограниченными ресурсами."])}
  },
  "activity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деятельность «ГибридЛаб®»"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обучение и моделирование HybridLab® не требуют прямого участия инструктора и могут быть организованы где угодно. \nПрежде чем прийти в HybridLab®, студенты готовятся, используя заранее подготовленные электронные письма. \nСистемы обучения. \nНа месте они получают приказы и инструкции, что и как делать по специально разработанным алгоритмам с использованием любого тренажерного оборудования. \nПреподаватель контролирует и оценивает успеваемость учащихся удаленно в любое время, пока студенты тренируются в классе HybridLab®."])},
    "items": {
      "0": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронное обучение"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® позволяет вам учиться без подключения к живому инструктору. \nОбучение может проходить где угодно."])}
      },
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Практическое моделирование"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® предоставляет возможность обучения посредством виртуальных симуляций, где не требуется непосредственное участие инструктора. \nЭто позволяет получить практические навыки работы с любым тренировочным оборудованием."])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обучение по принципу «равный-равному»"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® позволяет преподавателю удаленно отслеживать и оценивать достижения учащихся, пока они работают в классе HybridLab®."])}
      },
      "3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На основе успешных алгоритмов обучения"])}
      }
    }
  },
  "contacts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
    "desc_part": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть вопрос? \nПопробуйте найти его среди"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", а если вы не можете его найти, мы будем рады услышать ваше мнение."])}
    },
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с контактами ниже или отправьте нам запрос, заполнив форму, и мы свяжемся с вами как можно скорее."])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами, заполнив форму"])}
  },
  "course": {
    "reviews_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В данный момент нет обзоров."])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзывы"])},
    "subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Темы курса"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часто задаваемые вопросы"])},
    "abilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навыки, которые вы приобретете"])},
    "about_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О курсе"])},
    "methodology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О курсе"])},
    "show-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увидеть все"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатно"])},
    "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель курса"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи курса"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор курса"])},
    "how_to_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как учиться"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало:"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конец:"])},
    "events": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " события"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анкета"])},
    "subscription": {
      "employee_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество сотрудников в компании или учреждении"])},
      "preliminary_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительная цена подписки"])},
      "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сотрудники"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
      "price_per_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЕU/человеку"])}
    },
    "results": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " pезультаты"])},
    "activity": {
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению, елемент не найден"])},
      "session_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия вашего удаленного сеанса истек"])},
      "session_refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить сеанс"])}
    }
  },
  "discover-courses": {
    "filter-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск:"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Откройте для себя курсы"])}
  },
  "howitworks": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы создаем инновационные решения для обучения, которые легче освоить и к которым удобнее получить доступ."])}
  },
  "how-it-works": {
    "available_on_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно на мобильном"])},
    "advantages": {
      "types[0]": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem, ipsum dolor sit amet consectetur adipisicing elit."])}
      }
    }
  },
  "commoon": {
    "forgot_password_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминание пароля отправлено на электронную почту. \nадрес, пожалуйста, проверьте свой почтовый ящик и следуйте инструкциям."])}
  },
  "megamenu": {
    "private": {
      "course_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частный клиент"])},
      "links": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все курсы"])}
        }
      ],
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для частных лиц"])}
    },
    "business": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бизнес-клиент"])},
      "links": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы для компаний и организаций"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможности для бизнеса в HybridLab"])}
        }
      ]
    }
  },
  "banner": {
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите"])},
    "our_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["наше приложение"])},
    "welcome1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузите мобильное приложение"])},
    "welcome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и учитесь удобнее!"])}
  },
  "course_sidebar": {
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
    "location_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес местоположения"])},
    "location_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название местоположения"])}
  },
  "change_password": {
    "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый пароль"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
    "password_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль успешно изменен"])}
  }
}