<template>
  <div>
    <!-- <SimpleNavigation :auth="true"/> -->
    <Navigation />
    <PageHeader>
      <template #image>
        <img v-if="header" :src="header.image" :alt="header.meta_title" />
      </template>
      <template #content>
        <h2 v-dompurify-html="header?.title"></h2>
        <p v-dompurify-html="header?.description"></p>
      </template>
    </PageHeader>
    <HeaderBlock />
    <div>
      <div class="container">
        <div class="dash__row">
          <div class="dash__column dash__column--current-course">
            <CurrentCourse v-show="selectedCourse" :course="selectedCourse" />
          </div>
          <div class="dash__column dash__column--upcoming-courses">
            <div class="upcoming-courses">
              <h3 class="upcoming-courses__header">
                {{ $t("common.upcoming_courses") }}
              </h3>
              <Loading v-if="upcoming_courses_loading" />
              <div v-else>
                <div
                  v-if="upcoming_courses.length != 0"
                  class="upcoming-courses__list"
                >
                  <div
                    v-for="course in upcoming_courses.slice(0, 4)"
                    :key="course.title"
                    class="upcoming-courses__item"
                  >
                    <button
                      class="upcoming-courses__item__button"
                      :class="{ active: course.id === selectedCourse.id }"
                      @click="selectCourse(course)"
                      v-text="course.title"
                    />
                  </div>
                </div>
                <div v-else>
                  {{ $t("common.upcoming_courses_empty") }}
                </div>
              </div>
            </div>
            <div class="upcoming-calendar">
              <v-calendar
                :key="calendarKey"
                locale="lt"
                color="red"
                is-expanded
                :initial-page="{
                  month: selectedCourseStarMonth.toString(),
                  year: selectedCourseStartYear,
                }"
                :attributes="attributes"
                @dayclick="onDayClick"
              ></v-calendar>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <CourseList
          :courses="lastest_courses"
          :title="$t('common.lastest_solve_courses')"
          @filter:courses="filterCourses"
        />
      </div>
      <RecommendedCourseList />
    </div>

    <Footer />
  </div>
</template>
<script>
import RecommendedCourseList from "@/components/course/RecommendedCourseList";
import Footer from "@/components/Footer";
import Loading from "@/components/Loading";
import HeaderBlock from "@/components/dashboard/HeaderBlock";
import CurrentCourse from "@/components/dashboard/CurrentCourse";
import Navigation from "@/components/Navigation.vue";
import backService from "@/plugins/service.js";
import moment from "moment";
import { getParsedDate } from "../../../util/helpers";
import { usePageStore } from "@/store/modules/page.store";
import PageHeader from "@/components/PageHeader.vue";
import CourseList from "@/components/CourseList.vue";

export default {
  components: {
    CourseList,
    PageHeader,
    HeaderBlock,
    Footer,
    CurrentCourse,
    Navigation,
    Loading,
    RecommendedCourseList,
  },
  setup() {
    const pagesStore = usePageStore();

    return { pagesStore };
  },
  data() {
    return {
      courses: [],
      upcoming_courses_loading: false,
      upcoming_courses: [],
      selectedCourse: null,
      lastest_courses_loading: false,
      lastest_courses: [],
      days: [],
      loading: false,
      calendarKey: 0,
      header: null,
      selectedCourseStarMonth: moment().month() + 1,
      selectedCourseStartYear: moment().year(),
      swiperFeaturedCoursesOptions: {
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
      },
    };
  },
  computed: {
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      if (!this.selectedCourse) {
        return [];
      }

      const startDate = moment.utc(this.selectedCourse.date).startOf("day");
      const endDate = moment.utc(this.selectedCourse.endDate).startOf("day");

      return [
        {
          highlight: {
            start: {
              style: {
                background: "#6271DD",
                fontWeight: "bold",
                borderRadius: "5px",
              },
              contentStyle: {
                color: "white",
              },
            },
            end: {
              style: {
                background: "#6271DD",
                fontWeight: "bold",
                borderRadius: "5px",
              },
              contentStyle: {
                color: "white",
              },
            },
            base: {
              style: {
                background: "rgba(98, 113, 221, 0.1)",
              },
              contentStyle: {
                color: "#171F69",
              },
            },
          },
          dates: [
            {
              start: startDate.toDate(),
              span: endDate.diff(startDate, "days") + 1,
            },
          ],
        },
      ];
    },
  },
  watch: {
    selectedCourse: {
      immediate: true,
      async handler() {
        if (!this.selectedCourse) {
          return;
        }
        this.selectedCourseStarMonth =
          moment(this.selectedCourse.date).month() + 1;
        this.selectedCourseStartYear = moment(this.selectedCourse.date).year();
        this.calendarKey = this.calendarKey + 1;
      },
    },
    "i18n.global.locale": {
      immediate: true,
      async handler() {
        const { id } = await this.pagesStore.book("dashboard");
        this.header = await this.pagesStore.request(id);
      },
    },
  },
  async created() {
    await this.getLastestCourses();
    await this.getUpcomingCourses();
  },
  async created() {
    await this.getUpcomingCourses();
  },
  methods: {
    async filterCourses(query) {
      await this.getLastestCourses(query);
    },
    selectCourse(course) {
      this.selectedCourse = course;
    },
    getParsedDate,
    async getUpcomingCourses() {
      let vm = this;
      this.upcoming_courses_loading = true;
      await backService
        .get("/courses/purchased/upcoming")
        .then((response) => {
          vm.upcoming_courses_loading = false;
          vm.upcoming_courses = response.data;
          vm.selectedCourse = vm.upcoming_courses?.[0];
        })
        .catch(() => {
          vm.upcoming_courses_loading = false;
        });
    },
    async getLastestCourses(search = "") {
      let vm = this;
      this.lastest_courses_loading = true;
      await backService
        .get("/courses/purchased/latest", {
          params: { search },
        })
        .then((response) => {
          vm.lastest_courses_loading = false;
          vm.lastest_courses = response.data;
        })
        .catch(() => {
          this.lastest_courses_loading = false;
        });
    },
    onDayClick(day) {
      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "v-calendar/dist/style.css";

body {
  background: $color-dashboard-bg;
}

:deep(.vc-container) {
  border: none;
}

:deep(.vc-day) {
  color: $color-primary;
  padding: 0 5px;
}

:deep(.vc-day.weekday-7),
:deep(.vc-day.weekday-1) {
  color: red;
}

:deep(.vc-day-content) {
  font-weight: 500;
  font-size: $font-size-xs;
  font-family: $font-family;
  width: 32px;
  height: 32px;
}

:deep(.vc-day-content) {
  font-weight: 500;
  font-size: $font-size-xs;
  font-family: $font-family;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

:deep(.vc-weekday) {
  color: $color-menu-item;
  font-size: $font-size-xs;
  font-family: $font-family;
  font-weight: 500;
}

// .vc-arrow {
//     height: 32px;
//     width: 32px;
//     border: 1px solid #DCDEE9 !important;
//     background-color: #FFFFFF;
//     border-radius: 30px;
// }

:deep(.vc-title) {
  color: $color-primary;
  background: transparent;
  font-family: $font-family;
  font-size: $font-size-xs;
  font-weight: 500;
}

:deep(.vc-arrow) {
  background: transparent;
  width: 12px;
}

.upcoming-calendar {
  flex-basis: 100%;
  border: unset;
}

.upcoming-courses {
  flex-basis: 100%;

  &__header {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;

    margin-bottom: 23px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 320px;
  }

  &__item {
    display: flex;
    gap: 15px;

    &__button {
      font-family: $font-family;
      font-size: $font-size-md;
      color: $color-menu-item;
      border: unset;
      background: unset;
      width: 100%;
      text-align: left;
      padding: 15px;
      cursor: pointer;
    }

    &__button.active {
      font-family: $font-family;
      font-size: $font-size-md;
      color: $color-primary;
      border: unset;
      background: #f1f5f9;
      border-radius: 8px;
    }

    &:last-child {
      .upcoming-courses__item-dot:after {
        display: none;
      }
    }
  }

  &__item-dot {
    position: relative;
    display: flex;
    justify-content: center;

    &::before {
      content: "";
      height: 9px;
      width: 9px;
      background-color: $color-blue;
      border-radius: 30px;
      margin-top: 6px;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      top: -4px;
      width: 1px;
      height: 97px;
      background: #dcdee9;
      z-index: 1;
    }
  }

  &__course-name {
    color: $color-black;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__course-date {
    opacity: 0.5;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
  }
}

.dash {
  &__row {
    display: flex;
    align-items: flex-start;
    padding-top: 35px;
    padding-bottom: 30px;
    gap: 11px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    flex-basis: 100%;

    &--upcoming-courses {
      display: flex;
      gap: 48px;
      padding: 0 20px;
      background-color: $color-white;
      flex-basis: 100%;
      @media screen and (max-width: 900px) {
        flex-wrap: wrap;
      }
    }

    &--current-course {
      max-width: 40%;
      padding-right: 5px;

      @media screen and (max-width: 900px) {
        max-width: initial;
      }
    }
  }
}

.last-courses {
  margin-top: 40px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
    flex-wrap: wrap;

    h3 {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;
      text-decoration: none;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0 -1px 0 0 #dcdee9;
    height: 60px;
    min-width: 500px;
  }

  &__left-text {
    display: flex;
    align-items: center;

    a {
      color: $color-black;
      font-family: $font-family;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 21px;
    }
  }

  &__right-text {
    display: flex;
    align-items: center;
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 18px;
    gap: 20px;
  }
}
</style>
