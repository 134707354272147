<template>
  <Form :initial-values="initialValues" @submit="handleSubmit">
    <div class="settings__wrap">
      <div class="settings__item">
        <div class="settings__item-text">
          <div class="settings__item-text-name">{{ $t("common.country") }}</div>
          <div class="settings__item-text-description">
            {{ $t("common.select_country") }}
          </div>
        </div>
        <div class="settings__item-setting">
          <SelectInputField
            name="country"
            :placeholder="$t('common.country_placeholder')"
            :options="countriesStore.options"
            @input="(value) => setCountry(value)"
          />
        </div>
      </div>
      <div class="settings__item">
        <div class="settings__item-text">
          <div class="settings__item-text-name">
            {{ $t("common.preferred_language") }}
          </div>
        </div>
        <div class="settings__item-setting">
          <SelectInputField
            v-model="language"
            name="preferred_language"
            :placeholder="$t('common.language_placeholder')"
            :options="languages"
            @input="(value) => setLanguage(value)"
          />
        </div>
      </div>
      <div class="settings__item">
        <div class="settings__item-text">
          <div class="settings__item-text-name">{{ $t("common.city") }}</div>
          <div class="settings__item-text-description">
            {{ $t("common.select_city") }}
          </div>
        </div>
        <div class="settings__item-setting">
          <SelectInputField
            name="city"
            :placeholder="$t('common.city_placeholder')"
            :options="citiesStore.options"
            @input="(value) => setCity(value)"
          />
        </div>
      </div>
      <div class="settings__item">
        <div class="settings__item-text">
          <div class="settings__item-text-name">
            {{ $t("common.timezone") }}
          </div>
          <div class="settings__item-text-description">
            {{
              $t(
                "common.we_will_remind_you_about_the_courses_according_to_the_set_time_zone",
              )
            }}
          </div>
        </div>
        <div class="settings__item-setting">
          <SelectInputField
            name="timezone"
            :placeholder="$t('common.timezone_placeholder')"
            :options="timezonesStore.options"
            @input="(value) => setTimezone(value)"
          />
        </div>
      </div>
    </div>
  </Form>
</template>

<script setup>
import { Form } from "vee-validate";
import { useCountriesStore } from "@/store/modules/countries.store";
import SelectInputField from "../SelectInputField.vue";
import { useTimezonesStore } from "@/store/modules/timezones.store";
import { useCitiesStore } from "@/store/modules/cities.store";
import { useLocaleStore } from "@/store/modules/locale.store";
import { computed, defineEmits, defineProps, ref, watch } from "vue";
import i18n from "@/i18n";

const countriesStore = useCountriesStore();
const timezonesStore = useTimezonesStore();
const citiesStore = useCitiesStore();
const languagesStore = useLocaleStore();

const emit = defineEmits(["submit"]);
const props = defineProps({ initialValues: Object, loading: Boolean });

const language = ref(
  languagesStore.languages.find((language) =>
    language.code.startsWith(i18n.global.locale),
  )?.code || "lt-lt",
);

countriesStore.request();
timezonesStore.request();
countriesStore.setCurrent(props.initialValues.country || null);

const languages = computed(() =>
  languagesStore.languages.map((language) => ({
    name: language.name,
    value: language.code,
  })),
);

const setCountry = (value) => {
  countriesStore.setCurrent(value);
  emit("submit", { country: value });
};
const setCity = (value) => emit("submit", { city: value });
const setTimezone = (value) => emit("submit", { timezone: value });
const setLanguage = (value) => {
  const language = value.split("-");
  i18n.global.locale = language[0];
};

watch(() => countriesStore.currentCountryId, citiesStore.request);
</script>

<style lang="scss" scoped>
.spacer {
  padding: 10px;
}

#file_upload {
  display: none;
}

.settings {
  &__upload-photo {
    display: block;
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    line-height: 19px;
    cursor: pointer;
  }

  &__item-setting {
    :deep(.form-group) {
      margin: 0;
    }

    :deep(.form-control) {
      margin: 0;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      line-height: 19px;
    }
  }

  &__profile-name {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__profile-value {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__profile-fullname {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    line-height: 40px;

    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }

  &__profile-image {
    text-align: center;

    img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      border-radius: 64px;
    }
  }

  &__profile-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }
  }

  &__profile-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      gap: 10px;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      line-height: 19px;
      text-decoration: none;
    }
  }

  &__profile-settings-block {
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }

  &__profile-block {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-top: 60px;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-light-grey;
    padding: 8px 20px;
    min-height: 68px;
    gap: 16px;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 10px;
    }
  }

  &__item-text-name {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 400;
    line-height: 22px;
  }

  &__item-text-description {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    line-height: 22px;
  }
}
</style>
