<template>
  <div>
    <Navigation />
    <HeaderBlock />
    <ChangePassword v-if="changePassword" @close="close()" />
    <ChangeInformation v-if="changeAccount" @close="closeInformation()" />
    <QRCode v-if="modal" @close="toggleQr()" />
    <div>
      <div class="settings">
        <div class="container">
          <div class="settings__profile-block">
            <ImageInput
              :value="settingsStore.values.image"
              :loading="settingsStore.loading"
              @input="settingsStore.updateImage"
            />
            <div class="settings__profile-content">
              <div class="settings__profile-info">
                <div class="settings__profile-fullname">
                  {{ accountStore.values.firstname }}
                  {{ accountStore.values.lastname }}
                </div>
                <div class="settings__profile-change desktop-action">
                  <a
                    href="#"
                    @click.prevent="openChangeAccountInformationModal()"
                    v-text="$t('common.change_account_information')"
                  />
                </div>
              </div>
              <div class="settings__profile-settings">
                <div
                  class="settings__profile-settings-block settings-profile-birth"
                >
                  <div class="settings__profile-name">
                    {{ $t("common.birthday_date") }}
                  </div>
                  <div class="settings__profile-value">
                    {{ settingsStore.values.date_of_birth }}
                  </div>
                  <a
                    href="#"
                    @click.prevent="openChangeAccountInformationModal()"
                  >
                    {{ $t("common.change_account_information") }}</a
                  >
                </div>
                <div
                  class="settings__profile-settings-block settings-profile-gender"
                >
                  <div class="settings__profile-name">
                    {{ $t("common.gender") }}
                  </div>
                  <div class="settings__profile-value">
                    {{
                      settingsStore.values.gender === "male"
                        ? "Vyras"
                        : "Moteris"
                    }}
                  </div>
                </div>
                <div
                  class="settings__profile-settings-block settings-profile-email"
                >
                  <div class="settings__profile-name">
                    {{ $t("common.email") }}
                  </div>
                  <div class="settings__profile-value">
                    {{ accountStore.values.email }}
                  </div>
                </div>
                <div
                  class="settings__profile-settings-block settings-profile-phone"
                >
                  <div class="settings__profile-name">
                    {{ $t("common.phone_number") }}
                  </div>
                  <div class="settings__profile-value">
                    {{ accountStore.values.telephone || "Nenurodyta" }}
                  </div>
                </div>
              </div>
              <div class="settings__profile-change mobile-action">
                <a
                  href="#"
                  @click.prevent="openChangeAccountInformationModal()"
                  v-text="$t('common.change_account_information_short')"
                />
              </div>
              <div
                class="settings__profile-select-second-device desktop-action"
                @click="toggleQr()"
              >
                <img src="../../../assets/icons/qrcode-grey.svg" />
                {{ $t("common.connect_other_device") }}
              </div>
            </div>
          </div>

          <GeneralSettingsForm
            :initial-values="settingsStore.values"
            :loading="settingsStore.loading"
            @submit="settingsStore.updateAddress"
          />

          <div class="settings__wrap">
            <div class="settings__item">
              <div class="settings__item-text">
                <div class="settings__item-text-name">
                  {{ $t("common.password") }}
                </div>
                <div class="settings__item-text-description" />
              </div>
              <div class="settings__item-setting">
                <a href="#" @click.prevent="openChangePasswordModal()">
                  {{ $t("common.change_password") }}
                </a>
              </div>
            </div>
          </div>

          <PositionSettingsForm
            :initial-values="settingsStore.values"
            :loading="settingsStore.loading"
            @submit="settingsStore.updatePosition"
          />

          <div class="settings__logout">
            <div class="settings__logout-button" @click="auth2Store.logout">
              <img src="../../../assets/icons/logout-red.svg" alt="logout" />
              {{ $t("common.logout") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <RecommendedCourseList />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import RecommendedCourseList from "@/components/course/RecommendedCourseList";
import Navigation from "@/components/Navigation.vue";
import HeaderBlock from "@/components/dashboard/HeaderBlock.vue";
import { useAlertStore } from "@/store";
import ChangePassword from "@/components/modals/ChangePassword.vue";
import ChangeInformation from "@/components/modals/ChangeInformation.vue";
import GeneralSettingsForm from "@/components/form/dashboard/GeneralSettingsForm.vue";
import { useSettingsStore } from "@/store/modules/settings.store";
import PositionSettingsForm from "@/components/form/dashboard/PositionSettingsForm.vue";
import ImageInput from "@/components/inputs/ImageInput.vue";
import { useAccountStore } from "@/store/modules/account.store";
import QRCode from "@/components/modals/QRCode.vue";
import { useAuth2Store } from "@/store/modules/auth2.store";
import { ref } from "vue";

export default {
  components: {
    QRCode,
    RecommendedCourseList,
    HeaderBlock,
    Navigation,
    Footer,
    ChangePassword,
    ChangeInformation,
    GeneralSettingsForm,
    PositionSettingsForm,
    ImageInput,
  },
  props: {
    openChangePassword: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const alertStore = useAlertStore();
    const settingsStore = useSettingsStore();
    const accountStore = useAccountStore();
    const auth2Store = useAuth2Store();

    const modal = ref(false);

    accountStore.request();
    settingsStore.request();

    return {
      alertStore,
      settingsStore,
      accountStore,
      auth2Store,
      modal,
    };
  },
  data() {
    return {
      profile_image: "https://via.placeholder.com/90",
      loading: false,
      loadingAditional: false,
      changePassword: this.openChangePassword,
      changeAccount: false,
      additional_settings: {},
      timezones: [],
      countries: [],
      cities: [],
    };
  },
  methods: {
    close() {
      this.changePassword = false;
    },
    closeInformation() {
      this.changeAccount = false;
    },
    openChangeAccountInformationModal() {
      this.changeAccount = true;
    },
    openChangePasswordModal() {
      this.changePassword = true;
    },
    toggleQr() {
      this.modal = !this.modal;
    },
  },
};
</script>

<style lang="scss" scoped>
.spacer {
  padding: 10px;
}

#file_upload {
  display: none;
}

.settings {
  &__upload-photo {
    display: block;
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
    cursor: pointer;
  }

  &__item-setting {
    :deep(.form-group) {
      margin: 0;
    }

    :deep(.form-control) {
      margin: 0;
    }

    a {
      color: $color-menu-item;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 19px;
    }
  }

  &__profile-name {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 16px;
  }

  &__profile-value {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    line-height: 15px;
  }

  &__profile-fullname {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 26px;

    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }

  &__profile-info {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }
  }

  &__profile-change {
    a {
      color: $color-menu-item;
      font-family: $font-family;
      font-size: $font-size-xs;
      font-weight: 400;
      line-height: 10px;
      text-decoration: none;
    }

    &.desktop-action {
      display: none;

      @media screen and (min-width: 900px) {
        display: block;
      }
    }

    &.mobile-action {
      display: block;
      align-self: center;
      margin: 50px 0;

      @media screen and (min-width: 900px) {
        display: none;
      }
    }
  }

  &__profile-image {
    text-align: center;

    img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      border-radius: 64px;
    }
  }

  &__profile-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }
  }

  &__profile-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    @media screen and (max-width: 900px) {
      gap: 26px;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 19px;

      text-decoration: none;
    }
  }

  &__profile-settings-block {
    flex: 1;

    @media screen and (max-width: 900px) {
      min-width: calc(50% - 20px);
    }
  }

  &__profile-select-second-device {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    line-height: 10px;
    text-decoration: none;

    align-self: center;
    align-items: center;
    gap: 10px;
    margin-top: 50px;

    &.desktop-action {
      display: none;

      @media screen and (min-width: 900px) {
        display: flex;
      }
    }
  }

  &__profile-block {
    display: flex;
    align-items: center;
    gap: 26px 46px;
    padding-top: 60px;
    margin-bottom: 46px;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-light-grey;
    padding: 8px 20px;
    min-height: 68px;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 10px;
    }
  }

  &__item-text-name {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 400;
    line-height: 22px;
  }

  &__item-text-description {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    line-height: 22px;
  }

  &__logout {
    margin-top: 90px;
    margin-bottom: 50px;
  }

  &__logout-button {
    display: flex;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
    color: $color-light-coral;
    padding: 2px;
  }
}
</style>
