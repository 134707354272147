<template>
  <div>
    <QRCode v-if="modal" @close="toggleQr()" />
    <div class="header-block">
      <div class="container">
        <div class="header-block__wrap">
          <div class="header-block__block">
            <div class="tabs">
              <ul class="tabs__wrap">
                <router-link
                  v-for="(link, i) in routerLinks"
                  :key="i"
                  router-link-active
                  tag="li"
                  class="tabs__tab"
                  exact-active-class="tabs__tab--active"
                  class-active="activae"
                  :to="link.to"
                  >{{ $t(link.text) }}
                </router-link>
                <CustomButton
                  v-if="isMobile"
                  class="tabs__tab"
                  :type="'default'"
                  full-width
                  @click="auth2Store.logout"
                >
                  <template #text>
                    {{ $t("common.logout") }}
                  </template>
                </CustomButton>
              </ul>
            </div>
          </div>
          <div class="header-block__block">
            <a
              class="header-block__select-second-device"
              href=""
              @click.prevent="toggleQr()"
              ><img src="../../assets/icons/qrcode-grey.svg" />
              {{ $t("common.connect_other_device") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import QRCode from "@/components/modals/QRCode.vue";
import { ref } from "vue";
import { ROUTE } from "@/router/routenames";
import { isShoppingEnabled } from "@/util/helpers";
import CustomButton from "@/components/CustomButton.vue";
import { useAuth2Store } from "@/store/modules/auth2.store";
import useIsMobile from "@/util/useIsMobile";

const modal = ref(false);
const { isMobile } = useIsMobile();
const auth2Store = useAuth2Store();

const toggleQr = () => {
  modal.value = !modal.value;
};

const shoppingEnabledRoute = (name, text) =>
  isShoppingEnabled()
    ? [
        {
          to: { name },
          text: text,
        },
      ]
    : [];

const routerLinks = [
  {
    to: { name: ROUTE.Dashboard.Dashboard },
    text: "common.dashboard",
  },
  {
    to: { name: ROUTE.Dashboard.Courses },
    text: "common.my_courses",
  },
  ...shoppingEnabledRoute(
    ROUTE.Dashboard.Purchases,
    "common.purchases_history",
  ),
  ...shoppingEnabledRoute(
    ROUTE.Dashboard.Certificates,
    "common.my_certificates",
  ),
];

if (
  Object.keys(JSON.parse(localStorage.getItem("favoriteCourses")) || {}).length
) {
  routerLinks.push({
    to: { name: ROUTE.Dashboard.Favourites },
    text: "common.favourites",
  });
}
</script>
<style lang="scss" scoped>
.tabs {
  &__wrap {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-left: 0px;
    flex-direction: row;
    gap: 0px 40px;
    margin: 0px;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__tab {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-decoration: none;
    border: unset;
    min-width: unset;
    width: auto;
    text-align: left;
    padding-left: 0;

    @media screen and (max-width: 900px) {
      width: 100%;

      padding-top: 12px;
      padding-bottom: 12px;
    }

    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: solid 3px transparent;

    &:hover {
      border-bottom: solid 3px $color-primary;
    }

    &--active {
      color: $color-primary;
      border-bottom: solid 3px $color-primary;
    }
  }
}

.header-block {
  width: 100%;
  box-shadow: inset 0 -1px 0 0 #f1f5f9;
  background: white;

  &__welcome-text {
    color: $color-black;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    margin-bottom: 20px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__block {
    @media screen and (max-width: 900px) {
      margin-bottom: 20px;
    }
  }

  &__select-second-device {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    letter-spacing: 0;
    line-height: 19px;
    text-decoration: none;

    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
