import { defineStore } from "pinia";

export const useTimezonesStore = defineStore({
  id: "timezones",
  state() {
    return {
      data: null,
    };
  },
  getters: {
    options() {
      if (!this.data) return [];

      return this.data.map((timezone) => ({
        name: timezone,
        value: timezone,
      }));
    },
  },
  actions: {
    async request() {
      this.data = Intl.supportedValuesOf("timeZone");

      return true;
    },
  },
});
