<template>
  <div>
    <Modal :name="'qrcode'" :width="466" :toggle="true" @close="close()">
      <template #header>
        <h3>{{ $t("common.connect_other_device") }}</h3>
      </template>
      <template #body>
        <div class="qrcode">
          <div class="qrcode__list">
            <div class="qrcode__item">
              <div class="qrcode__number">1</div>
              <div class="qrcode__text">{{ $t("qr.0.text") }}</div>
            </div>
            <div class="qrcode__item">
              <div class="qrcode__number">2</div>
              <div class="qrcode__text">
                {{ $t("qr.1.text") }}
                <img src="../../assets/icons/qrcode-grey.svg" alt="" />
                {{ $t("common.icons") }}
              </div>
            </div>
            <div class="qrcode__item">
              <div class="qrcode__number">3</div>
              <div class="qrcode__text">{{ $t("qr.2.text") }}</div>
            </div>
          </div>

          <div class="qrcode__qrcode">
            <div v-if="error" class="qrcode__error">
              {{ $t("common.general_error") }}
            </div>
            <div v-else>
              <Loading v-if="loading" />
              <qr-code v-else :text="redirect_url"></qr-code>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from "@/components/Modal";
import backService from "@/plugins/service";
import QrCode from "vue-qrcode-component";
import Loading from "@/components/Loading";

export default {
  components: {
    Modal,
    QrCode,
    Loading,
  },
  data() {
    return {
      error: false,
      loading: false,
      redirect_url: "",
    };
  },
  async created() {
    await this.getQRCode();
  },
  methods: {
    async getQRCode() {
      let vm = this;
      this.loading = true;
      await backService
        .get("/courses/add-device")
        .then((response) => {
          vm.redirect_url = response.data.redirect_url;
          vm.loading = false;
        })
        .catch(() => {
          vm.error = true;
          vm.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.qrcode {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 24px;

    &:last-child {
      .qrcode__number {
        &:after {
          visibility: hidden;
        }
      }
    }
  }

  &__number {
    height: 40px;
    width: 40px;
    flex-basis: 100%;
    max-width: 40px;

    border: 1px solid #dcdee9;

    display: flex;
    align-items: center;
    justify-content: center;

    color: $color-primary;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    border-radius: 30px;
    position: relative;

    &:after {
      position: absolute;
      width: 1px;
      height: 28px;
      background: #dcdee9;
      content: "";
      bottom: -29px;
    }
  }

  &__error {
    font-family: $font-family;
    color: red;
    text-align: center;
    width: 100%;
  }

  &__text {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__qrcode {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    img {
    }
  }
}
</style>
