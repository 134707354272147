<template>
  <div>
    <router-link
      :to="{
        name: 'dashboard.certificates.show',
        params: { id: certificate.id },
      }"
      class="certificate"
    >
      <div class="certificate__content">
        <div class="certificate__name">
          <div class="certificate__author">{{ certificate.organisation }}</div>
          <div class="certificate__title">{{ certificate.title }}</div>
        </div>
        <div v-if="certificate.valid?.from" class="certificate__validity">
          <div class="certificate__validity-text">
            <img src="../../assets/medal.svg" alt="medal" />
            {{ $t("common.certificate_validity") }}
          </div>
          <div class="certificate__validity-date">
            <div class="certificate__start-date">
              {{ getParsedDate(certificate.valid.from) }}
            </div>
            <div class="certificate__end-date">
              {{ getParsedDate(certificate.valid.to) }}
            </div>
          </div>
          <div class="certificate__progress" :style="{ width: percent }">
            <div class="certificate__progress-inner"></div>
          </div>
        </div>
        <div v-else class="certificate__validity">
          <div
            class="certificate__validity-text certificate__validity-text--not-valid"
          >
            {{ $t("common.certificate_not_valid") }}
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
import moment from "moment";
import { getParsedDate } from "../../util/helpers";

export default {
  // https://stackoverflow.com/questions/30929857/calculate-percentage-between-two-dates-in-javascript
  props: {
    certificate: {},
  },
  computed: {
    percent() {
      var startOfDate = moment(this.certificate.valid.from),
        endDate = moment(this.certificate.valid.to),
        todayDate = moment();

      const daysDifference = moment(endDate).diff(startOfDate, "days");
      const difference = todayDate.diff(startOfDate, "days");

      const result = Math.round((difference / daysDifference) * 100);

      return 100 - result + "%";
    },
  },
  methods: { getParsedDate },
};
</script>
<style lang="scss" scoped>
.certificate {
  height: 94px;
  box-shadow: inset 0 -1px 0 0 #dcdee9;
  display: flex;
  align-items: center;
  text-decoration: none;
  min-width: 500px;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__progress {
    margin-top: 5px;
    position: relative;
    height: 3px;
    // width: 200px;
    background-color: $color-blue;
  }

  &__progress-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 300px;
    opacity: 0.1;
    border-radius: 3px;
    background-color: $color-blue;
  }

  &__validity-date {
    display: flex;
    justify-content: space-between;
    width: 300px;
  }

  &__validity-text {
    color: $color-black;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;

    display: flex;
    align-items: center;
    gap: 10px;

    &--not-valid {
      width: 300px;
      color: $color-red;
    }
  }

  &__validity {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__start-date,
  &__end-date {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 18px;
  }

  &__name {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__author {
    color: $color-black;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__title {
    color: $color-black;
    font-family: $font-family;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 400px;
  }
}
</style>
