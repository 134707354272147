<template>
  <Form :initial-values="initialValues" @submit="handleSubmit">
    <div class="settings__wrap">
      <div class="settings__item">
        <div class="settings__item-text">
          <div class="settings__item-text-name">
            {{ $t("common.workplace") }}
          </div>
          <div class="settings__item-text-description">
            {{ $t("common.enter_the_name_of_the_workplace_or_organization") }}
          </div>
        </div>
        <div class="settings__item-setting">
          <TextInputField
            v-model="workplace"
            name="organisation"
            :placeholder="$t('common.organisation_placeholder')"
            @input="debounceWorkplace"
          />
        </div>
      </div>
      <div class="settings__item">
        <div class="settings__item-text">
          <div class="settings__item-text-name">
            {{ $t("common.responsibilities") }}
          </div>
          <div class="settings__item-text-description">
            {{ $t("common.specify_the_position") }}
          </div>
        </div>
        <div class="settings__item-setting">
          <TextInputField
            v-model="position"
            name="position"
            :placeholder="$t('common.position_placeholder')"
            @input="debouncePosition"
          />
        </div>
      </div>
    </div>
  </Form>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue";
import { Form } from "vee-validate";
import TextInputField from "@/components/form/TextInputField.vue";
import debounce from "lodash.debounce";

const emit = defineEmits(["submit"]);
const props = defineProps({ initialValues: Object, loading: Boolean });

const workplace = ref(props.initialValues.organisation || "");
const position = ref(props.initialValues.position || "");

const setWorkplace = () => emit("submit", { organisation: workplace.value });
const setPosition = () => emit("submit", { position: position.value });

const debounceWorkplace = debounce(setWorkplace, 1000);
const debouncePosition = debounce(setPosition, 1000);
</script>

<style lang="scss" scoped>
.spacer {
  padding: 10px;
}

#file_upload {
  display: none;
}

.settings {
  &__upload-photo {
    display: block;
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
    cursor: pointer;
  }

  &__item-setting {
    :deep(.form-group) {
      margin: 0;
    }

    :deep(.form-control) {
      margin: 0;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 19px;
    }
  }

  &__profile-name {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__profile-value {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__profile-fullname {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }

  &__profile-image {
    text-align: center;

    img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      border-radius: 64px;
    }
  }

  &__profile-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }
  }

  &__profile-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      gap: 10px;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 19px;

      text-decoration: none;
    }
  }

  &__profile-settings-block {
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }

  &__profile-block {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-top: 60px;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  h4 {
    margin-bottom: 20px;

    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
  }

  &__wrap {
    padding-bottom: 60px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-light-grey;
    padding: 8px 20px;
    min-height: 68px;
    gap: 16px;

    &:last-child {
      border-bottom: 1px solid $color-light-grey;
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 10px;
    }
  }

  &__item-text-name {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 400;
    line-height: 22px;
  }

  &__item-text-description {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    line-height: 22px;
  }
}
</style>
