<template>
  <div class="course-page__row">
    <div class="course-page__column course-page__column--sidebar">
      <div class="subject-list">
        <div class="subject-list__wrap">
          <div
            v-for="theory in purchasedStore.course.theory"
            :key="theory"
            class="subject-list__item"
            :class="{
              'subject-list__item--active': theory.id === currentSubject?.id,
            }"
            @click="selectSubject(theory)"
          >
            <div class="subject-list__item-name">{{ theory.title }}</div>
            <div v-if="showCourseTime(theory)" class="subject-list__item-time">
              <CourseTime
                :duration="theory.duration"
                :active="currentSubject && theory.id !== currentSubject?.id"
              />
            </div>
            <div class="subject-list__item-progress">
              <CourseProgress
                :active="currentSubject && theory.id !== currentSubject?.id"
                :current="getCompletedCount(theory)"
                :max="getTotalCount(theory)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentSubject" class="course-page__column">
      <div class="subject-description">
        <div class="subject-description__header">
          <h3 v-if="currentSubject.title">{{ currentSubject.title }}</h3>
        </div>
        <div class="subject-description__content">
          <div v-dompurify-html="currentSubject.description"></div>
        </div>
      </div>

      <div v-if="currentSubject.sections" class="subjects">
        <CourseSubjectItem
          v-for="section in currentSubject.sections"
          :key="section?.id"
          :subject="section"
        />
      </div>
      <div v-else class="subjects">
        {{ $t("common.no_subjects") }}
      </div>
    </div>
  </div>
</template>

<script>
import CourseProgress from "@/components/course/CourseProgress";
import CourseTime from "@/components/course/CourseTime";
import CourseSubjectItem from "@/components/course/CourseSubjectItem.vue";
import { useRoute, useRouter } from "vue-router";
import { useAuth2Store } from "@/store/modules/auth2.store";
import { getPurchasedStore } from "@/store/modules/purchased.store";
import { ROUTE } from "@/router/routenames";
import { isShoppingEnabled } from "@/util/helpers";

export default {
  components: {
    CourseProgress,
    CourseSubjectItem,
    CourseTime,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const auth2Store = useAuth2Store();
    const purchasedStore = getPurchasedStore();

    return {
      route,
      router,
      auth2Store,
      purchasedStore,
    };
  },
  computed: {
    selectedSubjectId() {
      return this.route.params.subject;
    },
    refreshToken() {
      return `${this.$i18n.locale}|${this.auth2Store.token}`;
    },
    currentSubject() {
      return this.selectedSubjectId
        ? this.course?.theory.find(
            (section) => section.id === Number(this.selectedSubjectId),
          )
        : this.course?.theory[0];
    },
    course() {
      return this.purchasedStore.course || null;
    },
  },
  methods: {
    isShoppingEnabled,
    getTotalCount(t) {
      var total = 0;
      t.sections.forEach((element) => {
        total += element.parts.length;
      });

      return total;
    },
    getCompletedCount(t) {
      var total = 0;
      t.sections.forEach((element) => {
        let a = element.parts.filter((part) => part.completed == true);
        total += a.length;
      });

      return total;
    },
    selectSubject(subject) {
      this.router.replace({
        name: ROUTE.Course.Theory,
        params: { id: this.course.id, subject: subject.id },
      });
    },
    showCourseTime(theory) {
      return (
        isShoppingEnabled() ||
        theory.sections.some(
          (section) =>
            !section.parts.some((part) => part.module === "extlmsroom"),
        )
      );
    },
  },
};
</script>

<style lang="scss">
.subject-description {
  &__header {
    h3 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__content {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;

    img {
      width: 100%;
      height: auto;
    }

    p {
      margin-top: 28px;
      font-weight: 500;
    }

    ul {
      padding-left: 23px;
      font-size: $font-size-sm;
      margin-top: 24px;

      li {
        position: relative;
        list-style: none;

        &:after {
          content: "";
          position: absolute;
          left: -24px;
          top: 7px;
          background: url("../../assets/correct.svg");
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.subject-list {
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    border-radius: 4px;
    cursor: pointer;

    padding: 10px;
    gap: 15px;
    width: 100%;
    justify-content: space-around;
    color: black;
    border: 1px solid #dcdee9;

    @media screen and (max-width: 992px) {
      justify-content: flex-end;
      flex-wrap: wrap;
    }

    .subject-list__info {
      display: flex;
      gap: 10px;

      @media screen and (max-width: 992px) {
        justify-content: flex-end;
        flex-wrap: wrap;
      }
    }

    .subject-list__item-time {
      color: black;
    }

    &--active {
      background-color: $color-primary;
      border: solid 1px $color-primary;
      color: white;
    }
  }

  &__item-name {
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 16px;
    min-width: 160px;
    margin-right: auto;
  }
}

.course-page {
  &__row {
    display: flex;
    align-items: flex-start;
    gap: 43px;
    margin-top: 40px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    flex-basis: 100%;

    &--sidebar {
      flex-basis: 100%;
      max-width: 393px;
      @media screen and (max-width: 900px) {
        max-width: initial;
      }
    }
  }
}
</style>
