<template>
  <div>
    <!-- <SimpleNavigation :auth="true"/> -->
    <Navigation />
    <HeaderBlock />

    <div class="bg-grey">
      <div class="container">
        <div class="certificate">
          <div class="certificate__back">
            <router-link :to="{ name: 'dashboard.certificates' }">
              {{ $t("common.back") }}
            </router-link>
          </div>

          <div class="certificate__header">
            <h2>{{ $t("common.your_certificate") }}</h2>
          </div>

          <div class="certificate__row">
            <div class="certificate__column">
              <div class="certificate__content">
                <div class="certificate__image">
                  <img
                    v-if="certificate.thumbnail"
                    :src="certificate.thumbnail"
                    alt=""
                  />
                  <img v-else src="../../../assets/certificate.svg" alt="" />
                </div>
                <div v-if="certificate.warnings" class="certificate__warnings">
                  <p v-dompurify-html="certificate.warnings" />
                  <router-link
                    :to="{
                      name: 'course.theory',
                      params: { id: certificate.course.id },
                    }"
                  >
                    Eiti į kursą
                  </router-link>
                </div>
                <div v-else class="certificate__description">
                  <p v-dompurify-html="certificate.description" />
                  <CustomButton
                    :type="'round-white'"
                    :size="'md'"
                    :text="'Atsisiųsti PDF'"
                    @click="downloadPDF()"
                  />
                </div>
              </div>

              <div v-if="certificate.faq?.length" class="certificate__faq">
                <h4>{{ $t("common.faq") }}</h4>

                <accordion :padding="34">
                  <accordion-item
                    v-for="faq in certificate.faq"
                    :key="faq.question"
                  >
                    <template #accordion-trigger="{ isOpen }">
                      <div class="certificate__faq-header">
                        <h3 v-text="faq.question" />
                        <img
                          :src="isOpen ? MinusBlueIcon : PlusBlueIcon"
                          alt="svg"
                        />
                      </div>
                    </template>
                    <template #accordion-content>
                      <div class="certificate__faq-content">
                        {{ faq.answer }}
                      </div>
                    </template>
                  </accordion-item>
                </accordion>
              </div>
            </div>
            <div class="certificate__column certificate__column--sidebar">
              <div class="certificate__block-wrap">
                <div
                  v-if="certificate.valid?.from"
                  class="certificate__info-block"
                >
                  <div class="certificate__validity">
                    <div class="certificate__validity-image">
                      <img
                        src="../../../assets/file-certificate.svg"
                        alt="file"
                      />
                    </div>
                    <div class="certificate__validity-content">
                      <div class="certificate__validity-content-name">
                        {{ $t("common.certificate_valid") }}
                      </div>
                      <div class="certificate__validity-content-date">
                        <div class="certificate__validity-content-start-date">
                          {{ getParsedDate(certificate.valid.from) }}
                        </div>
                        <div class="certificate__validity-content-end-date">
                          {{ getParsedDate(certificate.valid.to) }}
                        </div>
                      </div>
                      <ProgressLine
                        :percent="percent"
                        :background-color="'rgba(0, 0, 0, 0.15)'"
                        :background-color-active="'white'"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="certificate__info-block certificate__info-block--not-valid"
                >
                  <div class="certificate__validity">
                    <div class="certificate__validity-image">
                      <img
                        src="../../../assets/file-certificate.svg"
                        alt="file"
                      />
                    </div>
                    <div class="certificate__validity-content">
                      <div class="certificate__validity-content-name">
                        {{ $t("common.certificate_not_valid") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="certificate__details">
                  <div class="certificate__details-wrap">
                    <div class="certificate__details-item">
                      <div class="certificate__details-image">
                        <img src="../../../assets/tachometer-fast.svg" />
                      </div>
                      <div class="certificate__details-content">
                        <div class="certificate__details-content-name">
                          {{ $t("common.course_end_result") }}
                        </div>
                        <div class="certificate__details-content-value">
                          {{ certificate.endResult }} %
                        </div>
                      </div>
                    </div>
                    <div class="certificate__details-item">
                      <div class="certificate__details-image">
                        <img src="../../../assets/calendar-day.svg" />
                      </div>
                      <div class="certificate__details-content">
                        <div class="certificate__details-content-name">
                          {{ $t("common.certificate_author") }}
                        </div>
                        <div class="certificate__details-content-value">
                          {{ certificate.organisation }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <CustomButton
                  :type="'dark'"
                  :text="$t('common.course_material')"
                  @click="courseMaterial()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <RecommendedCourseList />
    </div>

    <Footer />
  </div>
</template>

<script>
import moment from "moment";
import SimpleNavigation from "@/components/SimpleNavigation";
import HeaderBlock from "@/components/dashboard/HeaderBlock";
import CourseListHorizontal from "@/components/course/CourseListHorizontal";
import Footer from "@/components/Footer";
import CustomButton from "@/components/CustomButton";
import Accordion from "@/components/accordion/Accordion";
import AccordionItem from "@/components/accordion/AccordionItem";
import ProgressLine from "@/components/ProgressLine";
import Navigation from "@/components/Navigation";
import { useRoute, useRouter } from "vue-router";
import backService from "@/plugins/service";
import RecommendedCourseList from "@/components/course/RecommendedCourseList";
import MinusBlueIcon from "@/assets/icons/minus-blue.svg";
import PlusBlueIcon from "@/assets/icons/plus-blue.svg";
import { getParsedDate } from "../../../util/helpers";

export default {
  components: {
    SimpleNavigation,
    HeaderBlock,
    Footer,
    CustomButton,
    CourseListHorizontal,
    Accordion,
    AccordionItem,
    ProgressLine,
    Navigation,
    RecommendedCourseList,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    return {
      router,
      route,
    };
  },
  data() {
    return {
      loading: false,
      certificate: {
        valid: {
          from: "",
          to: "",
        },
      },
    };
  },
  computed: {
    PlusBlueIcon() {
      return PlusBlueIcon;
    },
    MinusBlueIcon() {
      return MinusBlueIcon;
    },
    percent() {
      if (this.certificate) {
        var startOfDate = moment(this.certificate.valid.from),
          endDate = moment(this.certificate.valid.to),
          todayDate = moment();

        var daysDifference = moment(endDate).diff(startOfDate, "days");
        var difference = todayDate.diff(startOfDate, "days");

        var result = Math.round((difference / daysDifference) * 100);

        return 100 - result + "%";
      } else {
        return "0%";
      }
    },
  },
  async mounted() {},
  async created() {
    await this.getCertificate(this.route.params.id);
  },
  methods: {
    getParsedDate,
    courseMaterial() {
      this.router.push({
        name: "course.theory",
        params: {
          id: this.certificate.courseId,
        },
      });
    },
    downloadPDF() {
      location.href = this.certificate.pdf;
    },
    async getCertificate(id) {
      let vm = this;
      this.loading = true;
      await backService
        .get(`/certificates/${id}`)
        .then((response) => {
          vm.certificate = response.data;
          vm.loading = false;
        })
        .catch(() => {
          vm.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.certificate {
  padding-top: 60px;

  &__warnings {
    font-family: $font-family;
    color: white;
    background: $color-red;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;

    :deep(a) {
      color: white;
    }

    :deep(ul) {
      padding: 0;
      list-style-type: none;
    }
  }

  &__back {
    margin-bottom: 15px;

    a {
      color: $color-black;
      font-family: $font-family;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 21px;
      text-decoration: none;
    }
  }

  &__validity {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 20px;
  }

  &__validity-image {
    height: 48px;
    width: 54px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__validity-content {
    width: 100%;
  }

  &__validity-content-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__validity-content-name {
    color: $color-white;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
  }

  &__validity-content-start-date,
  &__validity-content-end-date {
    color: $color-white;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__header {
    h2 {
      color: $color-black;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;

      margin-bottom: 30px;
    }
  }

  &__faq {
    margin-top: 60px;

    h4 {
      color: $color-black;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__faq-header {
    padding: 28px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-slg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
    }
  }

  &__faq-content {
    margin-bottom: 20px;
  }

  &__block-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__details {
    border: 1px solid #dcdee9;
    background-color: $color-white;
    display: flex;
    align-items: center;
    padding: 30px 20px;
  }

  &__details-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__details-item {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__details-image {
    height: 48px;
    width: 48px;
    border-radius: 30px;
    background-color: $color-light-blue;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__details-content {
  }

  &__details-content-name {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 3px;
  }

  &__details-content-value {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 16px;
  }

  &__info-block {
    background-color: $color-green;

    &--not-valid {
      background-color: gray;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 43px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    flex-basis: 100%;

    &--sidebar {
      margin-bottom: 10px;
      max-width: 393px;
      @media screen and (max-width: 900px) {
        max-width: initial;
      }
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 19px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__description {
    p {
      color: $color-black;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 28px;

      margin-bottom: 20px;
    }
  }

  .accordion__trigger {
    img {
      transition: all 0.5s;
    }
  }

  .accordion__trigger_active {
    img {
      transform: rotate(181deg);
    }

    h3 {
      opacity: 1;
      color: $color-black !important;
    }
  }
}
</style>
