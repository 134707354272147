<template>
  <div class="course-page__column course-page__column--sidebar">
    <CourseSidebarProducts
      v-if="course.products && course.products.length > 1"
      :products="course.products"
    />

    <div
      v-if="course.products && course.products.length === 1"
      class="course-page__additional-sidebar"
    >
      <CourseProductInfo
        :label="$t('course_sidebar.duration')"
        :text="courseDuration"
      />
      <CourseProductInfo
        :label="$t('course_sidebar.start_date')"
        :text="getParsedDate(course.products[0].start_date)"
      />
      <CourseProductInfo
        :label="$t('course_sidebar.end_date')"
        :text="getParsedDate(course.products[0].end_date)"
      />
      <CourseProductInfo
        :label="$t('course_sidebar.location_address')"
        :text="course.products[0].location_address"
      />
      <CourseProductInfo
        :label="$t('course_sidebar.location_name')"
        :text="course.products[0].location_name"
      />
      <CustomButton
        v-if="!isMobile"
        :full-width="true"
        :text="`${$t('common.add_to_cart')}  • `"
        @click="cartStore.add(course.products[0].product_id, 1)"
      >
        <template #text>
          <template
            v-if="course.products[0].price == 0 || !course.products[0].special"
          >
            <span v-text="course.products[0].price_formated" />
          </template>
          <template v-else>
            <span
              class="old_price"
              v-text="course.products[0].price_formated"
            />
            <span v-text="course.products[0].special?.price_formated" />
          </template>
        </template>
      </CustomButton>
    </div>
  </div>
</template>
<script setup>
import CourseSidebarProducts from "@/components/course/CourseSidebarProducts.vue";
import CustomButton from "@/components/CustomButton.vue";
import { useCartStore } from "@/store";
import useIsMobile from "@/util/useIsMobile";
import CourseProductInfo from "@/components/course/CourseProductInfo.vue";
import { getParsedDate } from "@/util/helpers";
import { computed } from "vue";

const props = defineProps({
  course: Object,
});

const cartStore = useCartStore();
const { isMobile } = useIsMobile();

const courseDuration = computed(() =>
  props.course.products?.[0].course_duration
    ? props.course.products?.[0].course_duration + " h"
    : null,
);
</script>

<style lang="scss" scoped>
.course-page {
  font-family: $font-family;

  &__column {
    flex-basis: 100%;

    &--sidebar {
      flex-basis: 100%;
      max-width: 393px;
      margin-bottom: 20px;

      @media screen and (max-width: 900px) {
        max-width: inherit;
        width: 100%;
        padding: 15px;
      }
    }
  }
}

p {
  font-family: $font-family;
  color: #000;
  font-size: $font-size-sm;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
}

.old_price {
  margin-right: 5px;
  color: $color-light-coral;
  text-decoration: line-through;
}
</style>
