<template>
  <div>
    <Modal :name="'account'" :width="466" :toggle="true" @close="close()">
      <template #header>
        <h3>{{ $t("common.change_information") }}</h3>
      </template>
      <template #body>
        <AccountSettingsForm
          :initial-values="accountStore.values"
          :loading="accountStore.loading"
          @submit="accountStore.updateData"
        />
        <br />
        <PersonalSettingsForm
          :initial-values="settingsStore.values"
          :loading="settingsStore.loading"
          @submit="settingsStore.updatePersonal"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import { useAlertStore } from "@/store";
import { useSettingsStore } from "@/store/modules/settings.store";
import PersonalSettingsForm from "../form/dashboard/PersonalSettingsForm.vue";
import { useAccountStore } from "@/store/modules/account.store";
import AccountSettingsForm from "../form/dashboard/AccountSettingsForm.vue";

export default {
  components: {
    Modal,
    PersonalSettingsForm,
    AccountSettingsForm,
  },
  setup() {
    const alertStore = useAlertStore();
    const settingsStore = useSettingsStore();
    const accountStore = useAccountStore();

    return {
      alertStore,
      settingsStore,
      accountStore,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped></style>
