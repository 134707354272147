import i18n from "@/i18n";
import moment from "moment/moment";

export const isShoppingEnabled = () =>
  process.env.VUE_APP_SHOPPING_FEATURES_ENABLED
    ? process.env.VUE_APP_SHOPPING_FEATURES_ENABLED === "true"
    : true;

export const getObjectPropByPath = (obj, path) => {
  const properties = path.split(".");
  let result = obj;

  for (const property of properties) {
    if (result && typeof result === "object" && property in result) {
      result = result[property];
    } else {
      return undefined;
    }
  }

  return result;
};

export const findStrTranslation = (
  str,
  strLangCode = "en",
  path = "common",
) => {
  if (!str || !i18n.global.messages || !i18n.global.messages[strLangCode]) {
    return str;
  }

  const messages = getObjectPropByPath(i18n.global.messages[strLangCode], path);

  let i18nKey = "";
  for (let key in messages) {
    if (
      i18n.global.t(path + "." + key, strLangCode).toLowerCase() ===
      str.toLowerCase()
    ) {
      i18nKey = key;
      break;
    }
  }

  return i18nKey.length ? i18n.global.t(path + "." + i18nKey) || str : str;
};

export const now = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const stripTags = (str) =>
  new DOMParser().parseFromString(str, "text/html")?.body?.textContent || "";

export const getFirstSentence = (str) => {
  const [firstSentence = ""] = str.split(".");
  return firstSentence;
};

export const splitFaqHtmlByTags = (
  str,
  headersSel = "p",
  contentSel = "ul",
) => {
  if (!str || !str.length) {
    return null;
  }

  const doc = new DOMParser().parseFromString(str.toString(), "text/html");
  const headers = doc.querySelectorAll(headersSel);
  const content = doc.querySelectorAll(contentSel);

  if (!headers.length) {
    console.assert(headers.length, "Could not find headers");
    return null;
  }

  const data = [];
  for (let i = 0; i < headers.length; i++) {
    data.push({
      header: headers[i].textContent,
      answers: Array.from(content[i].querySelectorAll("li")).map(
        (li) => li.textContent,
      ),
    });
  }

  return data;
};

export const getParsedDate = (date) => moment(date).locale("lt").format("LL");
