<template>
  <div>
    <Modal name="register" :width="466" :toggle="true" @close="close">
      <template #header>
        <h3 v-text="$t('common.change_password')" />
      </template>
      <template #body>
        <Form @submit="updatePassword">
          <TextField
            type="password"
            name="password"
            :placeholder="$t('change_password.old_password')"
          />
          <TextField
            type="password"
            name="new_password"
            :placeholder="$t('change_password.new_password')"
          />

          <div class="register__center">
            <CustomButton type="dark" :full-width="true">
              <template #text>
                <Loading v-if="loading" :size="15" color="white" />
                <div v-else v-text="$t('common.change')" />
              </template>
            </CustomButton>
          </div>
        </Form>
      </template>
    </Modal>
  </div>
</template>

<script>
import TextField from "@/components/form/TextField";
import CustomButton from "@/components/CustomButton.vue";
import Modal from "@/components/Modal";
import Loading from "@/components/Loading";
import { Form } from "vee-validate";
import { useAlertStore } from "@/store";
import backService from "@/plugins/service";
import { useSettingsStore } from "@/store/modules/settings.store";
import i18n from "@/i18n";

export default {
  components: {
    TextField,
    Modal,
    CustomButton,
    Form,
    Loading,
  },
  setup() {
    const alertStore = useAlertStore();
    const settingsStore = useSettingsStore();
    const textSuccess = i18n.global.t("change_password.password_changed");

    return {
      alertStore,
      settingsStore,
      textSuccess,
    };
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async updatePassword(values, actions) {
      let vm = this;
      this.loading = true;

      await backService
        .post("auth/password/change", {
          username: this.settingsStore.data?.username,
          password: values.password,
          newpassword: values.new_password,
        })
        .then(() => {
          vm.loading = false;
          actions.resetForm();
          vm.alertStore.success(this.textSuccess);
          vm.close();
        })
        .catch(() => {
          vm.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped></style>
