<template>
  <footer class="footer">
    <div v-if="isShoppingEnabled()" class="container">
      <div class="footer__wrap">
        <div class="footer__block footer__block--navigation">
          <div class="footer__block-navigation-wrap">
            <div class="footer__block-navigation-block">
              <h4>{{ $t("common.private") }}</h4>
              <ul>
                <li>
                  <router-link :to="{ name: 'discover' }">
                    {{ $t("footer.private.discover") }}
                  </router-link>
                </li>
                <li v-show="!auth2Store.isLoggedIn">
                  <router-link :to="{ name: 'login' }">
                    {{ $t("navigation.login") }}
                  </router-link>
                </li>
                <li v-show="!auth2Store.isLoggedIn">
                  <router-link :to="{ name: 'register' }">
                    {{ $t("navigation.register") }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="footer__block-navigation-block">
              <h4>{{ $t("common.business") }}</h4>
              <ul>
                <li>
                  <router-link :to="{ name: 'business-and-institutions' }">
                    {{ $t("footer.business.business_and_institutions") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'business-and-institutions' }">
                    {{ $t("footer.business.subscription") }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="footer__block-navigation-block">
              <h4>HybridLab</h4>
              <ul>
                <li>
                  <router-link :to="{ name: 'howitworks' }">
                    {{ $t("footer.hybridlab.how_it_works") }}
                  </router-link>
                </li>
                <li v-if="aboutPage">
                  <router-link
                    :to="{ name: 'page', params: { id: aboutPage } }"
                  >
                    {{ $t("footer.hybridlab.about") }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'contacts' }">
                    {{ $t("footer.hybridlab.help") }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer__block footer__block--download">
          <a
            v-if="getSocialUrl('VUE_APP_GOOGLE_APP_LINK')"
            :href="getSocialUrl('VUE_APP_GOOGLE_APP_LINK')"
            target="_blank"
          >
            <img src="../assets/footer/download-google-play-transparent.svg" />
          </a>
          <a
            v-if="getSocialUrl('VUE_APP_APPLE_APP_LINK')"
            :href="getSocialUrl('VUE_APP_APPLE_APP_LINK')"
            target="_blank"
          >
            <img src="../assets/footer/download-app-store-transparent.svg" />
          </a>
        </div>
      </div>
    </div>
    <div class="footer__footer-separator"></div>
    <div class="footer__footer-bottom container">
      <div class="footer__footer-bottom-wrap">
        <div
          class="footer__footer-bottom-block footer__footer-bottom-block--text"
        >
          <span>© 2024 HybridLab, Inc. </span>
          <span v-if="isShoppingEnabled()">All rights are reserved.</span>
          <router-link
            v-if="isShoppingEnabled()"
            :to="{ name: 'page', params: { id: privacy } }"
          >
            {{ $t("common.privacy_policy") }}
          </router-link>
        </div>
        <div
          v-if="isShoppingEnabled()"
          class="footer__footer-bottom-block footer__footer-bottom-block--partners"
        >
          <img src="@/assets/footer/Spalvotas_9001.png" alt="" />
          <img src="@/assets/footer/Spalvotas_14001.png" alt="" />
          <img src="@/assets/footer/Spalvotas_27001.png" alt="" />
        </div>
        <div v-if="!isShoppingEnabled()" class="footer__download-mobile">
          <a
            v-if="getSocialUrl('VUE_APP_GOOGLE_APP_LINK')"
            :href="getSocialUrl('VUE_APP_GOOGLE_APP_LINK')"
            target="_blank"
          >
            <img src="../assets/footer/download-google-play-transparent.svg" />
          </a>
          <a
            v-if="getSocialUrl('VUE_APP_APPLE_APP_LINK')"
            :href="getSocialUrl('VUE_APP_APPLE_APP_LINK')"
            target="_blank"
          >
            <img src="../assets/footer/download-app-store-transparent.svg" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { useAuth2Store } from "@/store/modules/auth2.store";
import { isShoppingEnabled } from "@/util/helpers";
import { ROUTE } from "@/router/routenames";

export default {
  setup() {
    const auth2Store = useAuth2Store();
    const privacy = process.env.VUE_APP_PRIVACY_PAGE;
    const cookie = process.env.VUE_APP_COOKIE_PAGE;
    const aboutPage = process.env.VUE_APP_ABOUT_PAGE;
    return {
      auth2Store,
      privacy,
      cookie,
      aboutPage,
    };
  },
  computed: {
    ROUTE() {
      return ROUTE;
    },
  },
  methods: {
    isShoppingEnabled,
    getSocialUrl(name) {
      return process.env[name];
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  background: white;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: inset 0 1px 0 0 #dcdee9;

  @media screen and (max-width: $breakpoint-lg) {
    height: auto;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;

    @media screen and (max-width: $breakpoint-lg) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &__block {
    padding-top: 50px;

    &--navigation {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: $breakpoint-lg) {
        padding-top: 40px;
        flex-basis: 100%;
        width: 100%;
      }
    }

    &--social-links {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          padding: 18px;
          height: 48px;
          // max-width: 18px;
        }
      }
    }

    &--download {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: $breakpoint-lg) {
        width: 100%;
        flex-direction: row;
        gap: 19px;
        padding-top: 30px;
        padding-bottom: 40px;
      }
    }
  }

  &__block-navigation-wrap {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__block-navigation-block {
    width: 100%;

    h4 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-md;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 19px;

      @media screen and (max-width: $breakpoint-lg) {
        font-weight: 500;
      }
    }

    ul {
      list-style-type: none;
      padding-left: 0px;

      a {
        color: $color-menu-item;
        font-family: $font-family;
        font-size: $font-size-md;
        letter-spacing: 0;
        line-height: 30px;
        text-decoration: none;
        @media screen and (max-width: $breakpoint-lg) {
          font-size: $font-size-md;
        }
      }

      @media screen and (max-width: $breakpoint-lg) {
        margin-top: 20px;
        margin-bottom: 50px;
      }
    }

    @media screen and (max-width: $breakpoint-lg) {
      width: auto;
      max-width: inherit;
      text-align: center;
    }
  }

  &__footer-separator {
    border-top: 1px solid #f1f5f9;

    @media screen and (max-width: $breakpoint-lg) {
      visibility: hidden;
    }
  }

  &__footer-bottom {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    letter-spacing: 0;
    line-height: 18px;
    text-align: justify;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;

    @media screen and (max-width: $breakpoint-lg) {
      height: auto;
    }
  }

  &__footer-bottom-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column-reverse;
    }
  }

  &__footer-bottom-block {
    &--text {
      a {
        width: 81px;
        color: $color-menu-item;
        font-family: $font-family;
        font-size: $font-size-sm;
        letter-spacing: 0;
        line-height: 18px;
        text-align: right;
      }

      @media screen and (max-width: $breakpoint-lg) {
        margin-bottom: 30px;
      }
    }

    &--partners {
      display: flex;
      align-items: center;
      gap: 20px;

      img {
        height: 43px;
      }

      @media screen and (max-width: $breakpoint-lg) {
        margin-bottom: 40px;
      }
    }
  }

  &__download-mobile {
    display: inline-flex;
    gap: 10px;

    @media screen and (max-width: $breakpoint-lg) {
      padding-top: 30px;
      padding-bottom: 15px;
    }
  }
}
</style>
