<template>
  <div class="course-page__row">
    <div class="course-page__column">
      <div class="course-page__content">
        <div class="course-page__section">
          <GlocareBanner />
          <h3
            v-if="
              purchasedStore.course.overview &&
              purchasedStore.course.overview.title
            "
            v-dompurify-html="
              $t(`course.${purchasedStore.course.overview.title}`)
            "
            class="course-page__section-header"
          />
          <div
            v-dompurify-html="purchasedStore.course.overview.description"
            class="course-page__section-description"
          />
          <div
            v-if="coursePurpose"
            v-dompurify-html="coursePurpose.answer"
            class="course-page__section-description"
          />
          <div
            v-if="courseTasks"
            v-dompurify-html="courseTasks.answer"
            class="course-page__section-description"
          />
          <div class="course-page__section-content">
            <accordion :padding="34">
              <accordion-item v-for="item in courseItems" :key="item">
                <template #accordion-trigger="{ isOpen }">
                  <div class="course-page__section-accordion-trigger">
                    <h3 v-text="$t(item.question)" />
                    <img
                      :src="isOpen ? MinusBlueIcon : PlusBlueIcon"
                      alt="svg"
                    />
                  </div>
                </template>
                <template #accordion-content>
                  <div v-dompurify-html="item.answer"></div>
                </template>
              </accordion-item>
            </accordion>
          </div>
        </div>
      </div>
    </div>
    <div class="course-page__column course-page__column--sidebar">
      <CourseSidebarDetails
        v-if="purchasedStore.course"
        :course="purchasedStore.course"
      />
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/accordion/Accordion";
import AccordionItem from "@/components/accordion/AccordionItem";
import CourseSidebarDetails from "@/components/course/CourseSidebarDetails";
import {
  COURSE_PURPOSE,
  COURSE_TASKS,
  getPurchasedStore,
} from "@/store/modules/purchased.store";
import GlocareBanner from "@/components/GlocareBanner.vue";
import { computed } from "vue";
import MinusBlueIcon from "@/assets/icons/minus-blue.svg";
import PlusBlueIcon from "@/assets/icons/plus-blue.svg";

export default {
  components: {
    Accordion,
    AccordionItem,
    CourseSidebarDetails,
    GlocareBanner,
  },
  setup() {
    const purchasedStore = getPurchasedStore();

    const coursePurpose = computed(() =>
      purchasedStore.course.overview?.items?.find(
        (item) => item.question === COURSE_PURPOSE,
      ),
    );
    const courseTasks = computed(() =>
      purchasedStore.course.overview?.items?.find(
        (item) => item.question === COURSE_TASKS,
      ),
    );
    const filteredOverviewItems = computed(() =>
      purchasedStore.course.overview?.items?.filter(
        (item) => ![COURSE_PURPOSE, COURSE_TASKS].includes(item.question),
      ),
    );
    const courseItems = computed(() => [
      ...filteredOverviewItems.value.map((item) => ({
        ...item,
        question: `course.${item.question}`,
      })),
      ...purchasedStore.course.faq.map((item) => ({
        ...item,
        question: `${item.question}`,
      })),
    ]);

    return {
      coursePurpose,
      courseTasks,
      purchasedStore,
      filteredOverviewItems,
      courseItems,
    };
  },
  data() {
    return {
      certificate: false,
      faqs: [],
    };
  },
  computed: {
    PlusBlueIcon() {
      return PlusBlueIcon;
    },
    MinusBlueIcon() {
      return MinusBlueIcon;
    },
  },
};
</script>

<style lang="scss">
.course-page {
  position: relative;

  &__section {
    margin-bottom: 60px;
    font-family: $font-family;
  }

  &__section-header {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  &__section-description {
    font-size: $font-size-sm;
    line-height: 30px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__section-accordion-trigger {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__section-content {
    p {
      color: $color-black;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 28px;
    }

    h4 {
      margin-top: 40px;
      margin-bottom: 15px;

      color: $color-blue;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 28px;
    }

    h3 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-slg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 43px;
    margin-top: 40px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    position: relative;
    width: 100%;
    flex-basis: 100%;

    &--sidebar {
      flex-basis: 100%;
      max-width: 393px;
      @media screen and (max-width: 900px) {
        max-width: initial;
      }
    }
  }
}

.accordion__trigger {
  img {
    transition: all 0.5s;
  }
}

.accordion__trigger_active {
  img {
    transform: rotate(181deg);
  }

  h3 {
    opacity: 1;
    color: $color-blue !important;
  }
}
</style>
