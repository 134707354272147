<template>
  <a
    v-for="lang in localeStore.languages"
    :key="lang"
    :data-lang="lang"
    class="navbar__dropdown-item"
    @click.prevent="changeLanguage(lang)"
  >
    <img :src="getLanguageImage(lang)" alt="Icon" />
    {{ lang.name }}
  </a>
</template>

<script setup>
import i18n from "@/i18n";
import { useLocaleStore } from "@/store/modules/locale.store";
import { defineProps } from "vue";

const localeStore = useLocaleStore();

const props = defineProps({
  callback: {
    type: Function,
    required: false,
  },
});

const changeLanguage = (lang) => {
  const language = lang.code.split("-");
  i18n.global.locale = language[0];

  if (props.callback && typeof props.callback === "function") {
    props.callback();
  }
};

const getLanguageImage = (lang) => {
  const language = lang.code.split("-");
  return require("@/assets/menu/" + language[0] + ".png");
};

if (!localeStore.languages.length) {
  localeStore.request();
}
</script>

<style scoped lang="scss">
.navbar__dropdown-item {
  cursor: pointer;
}
</style>
