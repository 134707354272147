<template>
  <div>
    <Navigation />
    <MessageHeader :recent-contacts="recent_contacts" />

    <div class="messages">
      <div class="container">
        <div class="messages__wrap">
          <div class="messages__block messages__block--sidebar">
            <MessageThreads @thread="selectedThread" />
          </div>
          <div class="messages__block">
            <div
              v-if="currentThread !== null"
              class="messages__messages-container"
            >
              <div class="messages__header">
                <h3 class="messages__title">
                  {{ getName(currentThread) }}
                </h3>
                <div class="options"></div>
              </div>
              <div ref="messages" class="messages__content">
                <MessageItem
                  v-for="(message, index) in messages"
                  :key="index"
                  :message="message"
                />
              </div>
              <div class="messages__footer">
                <form
                  class="messages__input-group"
                  @submit.prevent="sendMessage"
                >
                  <input
                    v-model="message"
                    class="messages__input"
                    placeholder="Type A message"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navigation from "@/components/Navigation.vue";
import MessageHeader from "@/components/message/MessageHeader.vue";
import MessageItem from "@/components/message/MessageItem.vue";
import MessageThreads from "@/components/message/MessageThreads.vue";

export default {
  components: {
    Navigation,
    MessageHeader,
    MessageItem,
    MessageThreads,
  },
  data() {
    return {
      loading: false,
      message: "",
      messages: [
        {
          attachments: [],
          me: true,
          message: "Lorem5",
          user: {
            name: "test",
          },
        },
      ],
      currentThread: null,
      recent_contacts: [
        {
          name: "",
          image: "",
        },
      ],
    };
  },
  methods: {
    onSubmit() {},
    getName(thread) {
      let name = "";

      thread.users.forEach((user) => {
        name += user.name + ", ";
      });

      return name;
    },
    selectedThread(thread) {
      this.currentThread = thread;
    },
    scrollToElement() {
      const el = this.$refs.messages;

      this.$nextTick(() => {
        // DOM updated
        el.scrollTop = el.scrollHeight;
      });
    },
    sendMessage() {
      this.messages.push({
        attachments: [],
        me: true,
        message:
          "Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.",
        user: {
          name: "test",
        },
      });

      this.messages.push({
        attachments: [
          {
            type: "image",
            url: "https://via.placeholder.com/260x150",
          },
        ],
        me: false,
        message: this.message,
        user: {
          name: "test",
        },
      });

      this.message = "";

      this.scrollToElement();
    },
  },
};
</script>
<style lang="scss" scoped>
$header-height: 90px;
$messager-contacts-header-height: 110px;

$messager-header-height: 80px;
$messager-footer-height: 70px;
$messager-content-height: $messager-contacts-header-height + $header-height +
  $messager-footer-height + $messager-header-height;

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $color-primary;
  color: $color-primary;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &:after,
  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &:before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-primary;
    color: $color-primary;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &:after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $color-primary;
    color: $color-primary;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $color-primary;
  }
  50%,
  100% {
    opacity: 0.2;
    background-color: $color-primary;
  }
}

.messages {
  &__wrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__messages-container {
  }

  &__block {
    flex-basis: 0;
    flex-grow: 999;
    // height: 100vh;

    &:first-child {
      box-shadow: inset -1px 0 0 0 #dcdee9;
    }

    &--sidebar {
      flex-basis: 350px;
      flex-grow: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    padding: 20px;
  }

  &__title {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
  }

  &__content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    height: calc(100vh - $messager-content-height);
    padding: 20px;
  }

  &__footer {
    height: $messager-footer-height;
    display: flex;
    align-items: center;
  }

  &__input-group {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0px 20px;
  }

  &__input {
    padding: 20px 50px;
    width: 100%;
    border: 1px solid #dcdee9;
    border-radius: 4px;
    background-color: #ffffff;
  }
}
</style>
