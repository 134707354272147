<template>
  <div class="pagination-container">
    <div class="pagination-container__wrap">
      <div class="pagination-container__block">
        <div class="pagination-container__text">
          {{ $t("common.courses") }} {{ from }} - {{ to }} iš {{ total }}
        </div>
      </div>
      <div class="pagination-container__block">
        <paginate
          :model-value="page"
          :page-count="pageCount"
          :page-range="3"
          :margin-pages="2"
          :click-handler="update"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        />
      </div>
      <div class="pagination-container__block"></div>
    </div>
  </div>
</template>
<script>
import Paginate from "vuejs-paginate-next";

export default {
  components: {
    Paginate,
  },
  props: {
    page: Number,
    pageCount: Number,
    total: Number,
    from: Number,
    to: Number,
  },
  methods: {
    update(page) {
      this.$emit("update", page);
    },
  },
};
</script>
<style lang="scss">
.pagination-container {
  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__block {
    flex-basis: 100%;
  }
}

.pagination {
  list-style-type: none;
  padding-left: 0px;
  display: flex;
  align-items: center;
  gap: 10px;

  li {
    display: flex;
    align-items: center;
    height: 36px;
    width: 36px;
    border-radius: 5px;
    justify-content: center;

    &.disabled {
      border: 1px solid #dcdee9;
      border-radius: 5px;
    }

    &:last-child {
      border: 1px solid #dcdee9;
      border-radius: 5px;
    }

    &.active {
      background: $color-primary;
      color: white;
    }

    &:hover {
      background: $color-primary;
      color: white;
    }

    a {
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  &__active {
    background: $color-primary;
    color: white;
  }

  &__prev {
    border: 1px solid #dcdee9;
  }

  &__next {
    border: 1px solid #dcdee9;
  }
}
</style>
