import { defineStore } from "pinia";
import { ref } from "vue";

export const usePopupStore = defineStore("popup", () => {
  const menu = ref(false);

  const openMenu = () => (menu.value = true);
  const closeMenu = () => (menu.value = false);

  return {
    menu,

    closeMenu,
    openMenu,
  };
});
