<template>
  <div class="subject">
    <div class="subject__header" v-text="subject.title" />
    <div class="subject__wrap">
      <template v-for="material in subject.parts" :key="material.id">
        <accordion v-if="material.is_group" :padding="34">
          <accordion-item :disabled="!material.subparts.length">
            <template #accordion-trigger="{ isOpen }">
              <div class="subject__accordion-trigger">
                <div class="subject__accordion-title">
                  <div
                    v-if="material.completion === 1"
                    class="subject__completion-status"
                    :class="{
                      'subject__completion-status--done': material.completed,
                    }"
                    @click.stop="setCompletion(material)"
                  >
                    <img :src="CorrectWhiteIcon" alt="check" />
                  </div>
                  <h3 v-dompurify-html="material.title" />
                </div>
                <img
                  v-if="material.subparts.length"
                  :src="isOpen ? MinusBlueIcon : PlusBlueIcon"
                  alt="svg"
                />
              </div>
            </template>
            <template #accordion-content>
              <div class="group-items">
                <CourseMaterialItem
                  v-for="part in material.subparts"
                  :key="part.id"
                  :material="part"
                  @set:completion="setCompletion"
                />
              </div>
            </template>
          </accordion-item>
        </accordion>
        <div v-else class="group-items">
          <CourseMaterialItem
            :material="material"
            @set:completion="setCompletion"
          />
        </div>
      </template>
    </div>
    <accordion v-if="subject.pages.length" :padding="34">
      <accordion-item v-for="page in subject.pages" :key="page.id">
        <template #accordion-trigger="{ isOpen }">
          <div class="subject__accordion-trigger">
            <div class="subject__accordion-trigger__title-wrapper">
              <div
                v-if="page.completion === MANUAL_COMPLETION"
                class="subject__completion-status"
                :class="{
                  'subject__completion-status--done': page.completed,
                }"
              >
                <img
                  :src="CorrectWhiteIcon"
                  alt="check"
                  @click="setPageCompletion(page)"
                />
              </div>
              <h3 v-text="page.title" />
            </div>
            <img :src="isOpen ? MinusBlueIcon : PlusBlueIcon" alt="svg" />
          </div>
        </template>
        <template #accordion-content>
          <div v-dompurify-html="page.content" />
        </template>
      </accordion-item>
    </accordion>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import CourseMaterialItem from "@/components/course/CourseMaterialItem.vue";
import PlusBlueIcon from "@/assets/icons/plus-blue.svg";
import MinusBlueIcon from "@/assets/icons/minus-blue.svg";
import CorrectWhiteIcon from "@/assets/correct_white.svg";
import { useRoute } from "vue-router";
import {
  MANUAL_COMPLETION,
  useCompletionsStore,
} from "@/store/modules/completions.store";

defineProps({ subject: {} });

const completionsStore = useCompletionsStore();

const { setCompleted } = completionsStore;

const route = useRoute();

const setCompletion = async (material) => {
  await setCompleted(
    material.module,
    material.id,
    !material.completed,
    route.params.id,
  );
};

const setPageCompletion = async (page) => {
  if (page.completed) {
    return;
  }
  await setCompleted(
    page.module,
    page.course_module_id,
    !page.completed,
    route.params.id,
  );
};
</script>

<style lang="scss" scoped>
.subject {
  margin-bottom: 68px;
  font-family: $font-family;

  &__completion-status {
    height: 24px;
    width: 32px;
    background-color: rgba($color-black, 0.4);
    border-radius: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 24px;
    min-width: 32px;

    img {
      transform: unset;
    }

    &--done {
      background: $color-green;
    }
  }

  .group-items {
    padding: 0 24px;
    box-shadow: inset 0 -1px 0 0 #dcdee9;

    &:last-child {
      box-shadow: none;
    }
  }

  .accordion {
    margin-bottom: 0;

    .group-items {
      padding: 0 24px 0 0;
    }
  }

  &__header {
    color: $color-black;
    font-size: $font-size-md;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 -1px 0 0 #dcdee9;
  }

  &__show-more {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 70px;
    gap: 15px;

    span {
      height: 34px;
      width: 34px;
      border-radius: 17px;
      background-color: $color-primary;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__wrap {
    margin-top: 20px;
  }

  .accordion__item {
    box-shadow: inset 0 -1px 0 0 #dcdee9;
    border-bottom: unset;
  }

  &__accordion-title {
    display: flex;
    gap: 10px;
  }

  &__accordion-trigger {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    h3 {
      color: $color-blue !important;
      font-weight: 500;
      font-family: $font-family;
      font-size: $font-size-slg;
    }
  }
}
</style>
