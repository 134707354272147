<template>
  <div>
    <Navigation />
    <div class="container">
      <div class="cart">
        <h2>{{ $t("common.cart") }}</h2>
        <div class="cart__row">
          <div class="cart__column">
            <div v-if="!cartStore.isEmpty" class="cart__wrap">
              <CartItem v-for="t in cartStore.products" :key="t" :course="t" />
            </div>
            <div v-else>
              <div class="cart__error">{{ $t("common.cart_is_empty") }}</div>
            </div>
          </div>
          <div
            class="cart__column cart__column--sidebar"
            :style="{ visibility: cartStore.isEmpty ? 'hidden' : 'inherit' }"
          >
            <div class="cart__sidebar">
              <div class="cart__total-text">
                {{ $t("common.total_payment") }}
              </div>
              <div class="cart__total">€ {{ cartStore.totalCount }}</div>

              <PayWithDiscount @submit="confirm" />
            </div>
          </div>
        </div>

        <PopularCourses />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import CartItem from "@/components/cart/CartItem.vue";
import Navigation from "@/components/Navigation.vue";
import { useAlertStore, useCartStore } from "@/store";
import axios from "axios";
import { useRouter } from "vue-router";
import PopularCourses from "@/components/course/PopularCourses.vue";
import PayWithDiscount from "@/components/cart/PayWithDiscount.vue";

export default {
  components: {
    Navigation,
    Footer,
    CartItem,
    PopularCourses,
    PayWithDiscount,
  },
  setup() {
    const cartStore = useCartStore();
    const router = useRouter();
    const alertStore = useAlertStore();
    return {
      cartStore,
      router,
      alertStore,
    };
  },
  data() {
    return {
      courses: [],
      showDiscountForm: false,
    };
  },
  created() {
    this.getFeaturedProducts();
    // this.cartStore.getItems();
  },
  methods: {
    addDiscountCode() {
      this.showDiscountForm = !this.showDiscountForm;
    },
    async confirm() {
      let vm = this;
      vm.router.push({
        name: "cart.payment",
      });
    },
    async getFeaturedProducts() {
      let vm = this;
      this.featuredCoursesLoading = true;
      await axios
        .get("/featured")
        .then((response) => {
          if (response.data.success) {
            vm.courses = response.data.data[0].products;
            vm.featuredCoursesLoading = false;
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.cart {
  &__error {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__total-text {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__total {
    color: $color-black;
    font-family: $font-family;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 46px;

    margin-bottom: 25px;
  }

  h2 {
    color: $color-black;
    font-family: $font-family;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 43px;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    flex-basis: 100%;

    &--sidebar {
      flex-basis: 500px;
      @media screen and (max-width: 900px) {
        flex-basis: 100%;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
