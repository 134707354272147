<template>
  <div class="message" :class="{ 'message--them': message.me == true }">
    <div class="message__container">
      <div class="message__user">
        <img src="https://via.placeholder.com/30" />
      </div>
      <div class="message__messages">
        <div
          v-if="message.attachments.length"
          class="message__content"
          :class="{
            'message__content--attachment': message.attachments.length,
          }"
        >
          <div v-for="(attachment, index) in message.attachments" :key="index">
            <div v-if="attachment.type == 'image'">
              <img :src="attachment.url" alt="attachment" />
            </div>
          </div>
        </div>
        <div class="message__content">{{ message.message }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.message {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-top: 10px;
  width: 100%;

  &--me {
    justify-content: flex-start;
  }

  &--them {
    justify-content: flex-end;

    .message__user {
      display: none;
    }

    .message__content {
      color: white;
      background-color: $color-primary !important;
      background: linear-gradient(
        308.17deg,
        rgba(50, 148, 198, 0) 0%,
        rgba(50, 148, 198, 0.4) 100%
      );
    }
  }

  &__container {
    display: flex;
    align-items: flex-end;
    gap: 10px;
  }

  &__messages {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__user {
    img {
      border-radius: 30px;
    }
  }

  &__content {
    background-color: rgba(21, 31, 109, 0.05);
    border-radius: 4px;
    color: $color-black;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 10px 15px;
    max-width: 260px;
    min-width: 60px;

    &--attachment {
      background: transparent;
      padding: 0px;
    }
  }

  &__footer {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 18px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
