<template>
  <div v-if="isShoppingEnabled()" class="container">
    <CourseListHorizontal>
      <template #header>
        {{ $t("common.popular_courses") }}
      </template>
      <template #body>
        <swiper
          :loop="false"
          :breakpoints="swiperFeaturedCoursesOptions.breakpoints"
          :slides-per-view="4"
          :space-between="50"
          @slideChange="onSlideChange"
        >
          <Loading v-if="productsStore.loading" />
          <swiper-slide v-for="course in productsStore.list" :key="course.id">
            <CoursePopular :course="course" />
          </swiper-slide>
        </swiper>
      </template>
    </CourseListHorizontal>
  </div>
</template>
<script>
import CourseListHorizontal from "@/components/course/CourseListHorizontal.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import CoursePopular from "@/components/course/Course.vue";
import Loading from "@/components/Loading.vue";
import { useProductsStore } from "@/store/modules/products.store";
import { isShoppingEnabled } from "@/util/helpers";

export default {
  components: {
    CourseListHorizontal,
    Swiper,
    SwiperSlide,
    CoursePopular,
    Loading,
  },
  setup() {
    const productsStore = useProductsStore();

    const onSlideChange = () => {};

    return {
      onSlideChange,
      productsStore,
    };
  },
  data() {
    return {
      swiperFeaturedCoursesOptions: {
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      },
    };
  },
  created() {
    this.productsStore.request(1, 5, "bestsellers");
  },
  methods: { isShoppingEnabled },
};
</script>

<style lang="scss">
.swiper-slide {
  display: flex;
  height: unset;
}
</style>
