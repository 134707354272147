<template>
  <CoursePage :course="course" :back-route="backRoute">
    <template #content>
      <div id="about" class="course-page__section">
        <h3 class="course-page__section-header">
          {{ $t("course.about_course") }}
        </h3>
        <div class="course-page__section-content">
          <div>
            <p v-html="course.fullDescription"></p>
          </div>

          <template v-if="course.abilities?.length">
            <h4>
              {{ $t("course.abilities") }}
            </h4>

            <div class="tags">
              <div
                v-for="ability in course.abilities"
                :key="ability"
                class="tags__tag"
              >
                {{ ability }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </CoursePage>
</template>

<script setup>
import CoursePage from "@/views/pages/CoursePage.vue";
import { computed, onMounted } from "vue";
import { useProductsStore } from "@/store/modules/products.store";
import { useRoute } from "vue-router";
import useIsMobile from "@/util/useIsMobile";
import { ROUTE } from "@/router/routenames";

defineProps({
  backRoute: { type: String, default: ROUTE.Discover },
});

const route = useRoute();
const { checkWidth } = useIsMobile();

const productsStore = useProductsStore();

const course = computed(() => productsStore.current || {});

onMounted(() => {
  productsStore.requestCurrent(route.params.id);
  checkWidth();
});
</script>

<style lang="scss" scoped>
.course-page {
  &__section-content {
    color: $color-blue;
  }
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  &__tag {
    border-radius: 17px;
    background-color: $color-light-blue;

    padding: 9px 20px;

    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-xs;
    letter-spacing: 0;
    line-height: 18px;

    text-transform: uppercase;

    &:hover {
      background-color: darken($color-light-blue, 2);
    }
  }
}
</style>
