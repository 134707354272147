<template>
  <div class="form-group">
    <label v-if="label" :for="name">{{ label }} </label>
    <div class="input">
      <Field
        v-slot="{ errors }"
        v-model="inner_value"
        :label="label.toLowerCase()"
        :rules="rules"
        :name="name"
      >
        <multiselect
          v-model="value"
          :value="inner_value"
          :options="options"
          class="form-control"
          :class="{ 'form-control--is-invalid': !!errors.length }"
          :deselect-label="''"
          :selected-label="''"
          track-by="value"
          label="name"
          :placeholder="placeholder"
          :searchable="true"
          :allow-empty="false"
          @select="valueChanged"
          @input="valueChanged"
        >
          <template #singleLabel="{ option }">
            <strong>{{ option.name }}</strong>
          </template>
        </multiselect>
      </Field>
      <ErrorMessage v-slot="{ message }" :name="name">
        <p class="error">{{ message }}</p>
      </ErrorMessage>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { ErrorMessage, Field } from "vee-validate";

/** @deprecated */
export default {
  components: {
    Multiselect,
    Field,
    ErrorMessage,
  },
  props: {
    valueAsObject: {
      default: false,
    },
    placeholder: {},
    options: {},
    name: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    inputValue: {
      type: [Object, String],
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
  },
  data() {
    return {
      inner_value: "",
      value: { name: "Pasirinkite", value: "" },
    };
  },
  created() {
    this.inner_value = this.inputValue;
    this.value = this.options.find(
      (option) => option.value === this.inputValue,
    );
  },
  methods: {
    valueChanged(val) {
      if (this.valueAsObject) {
        this.inner_value = val.value;
      } else {
        this.inner_value = val;
      }

      this.$emit("input", val.value);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.error {
  color: $color-red;
  font-family: $font-family;
  font-size: $font-size-sm;
  margin-top: 5px;
}

.form-control {
  background: transparent;
  border: none;
  border-radius: 8px;
  color: $color-blue;
  font-family: $font-family;
  font-size: $font-size-sm;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  min-height: 59px;

  &--is-invalid {
    border-color: $color-red;
  }
}

.multiselect__tags {
  background: $color-light-grey !important;
  border: 1px solid $color-light-grey;
  border-radius: 8px;
  min-width: 300px;
  min-height: 59px;
  padding: 17.5px 15px;
}

.multiselect__placeholder {
  font-size: $font-size-md;
  font-weight: 400;
  line-height: 14px;
  color: $color-menu-item;
  margin: 0;
}

.multiselect__select {
  min-height: 50px;
}

.multiselect__single {
  background: $color-light-grey !important;
  margin-bottom: 0;
}

.multiselect__option--highlight {
  background: $color-blue;

  &:after {
    background: $color-blue;
  }
}

.multiselect__input {
  background: $color-light-grey;
}
</style>
