<template>
  <div class="form-group">
    <label v-if="label" :for="id">
      {{ label }}
      <slot name="label" />
    </label>
    <Multiselect
      :id="id"
      v-model="innerValue"
      class="form-control"
      :options="options"
      track-by="value"
      label="name"
      :placeholder="placeholder"
    />
    <ErrorMessage
      v-if="errors.length && name"
      v-slot="{ message }"
      :name="name"
    >
      <p class="error">{{ message }}</p>
    </ErrorMessage>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineOptions, defineProps } from "vue";
import Multiselect from "vue-multiselect";
import { uniqid } from "@/plugins/misc";
import { ErrorMessage } from "vee-validate";

const id = uniqid("select-input-");
defineOptions({ inheritAttrs: false });
const emit = defineEmits(["update:modelValue", "input"]);
const props = defineProps({
  modelValue: String,
  options: Array,
  label: String,
  placeholder: String,
  name: String,
  errors: {
    type: Array,
    default: () => [],
  },
});

const innerValue = computed({
  get: () => {
    if (!props.modelValue) return null;
    if (!props.options) return null;

    return props.options.find((x) => x.value === props.modelValue) || null;
  },
  set: (value) => {
    if (value) {
      emit("update:modelValue", value.value);
    } else {
      emit("update:modelValue", undefined);
    }
  },
});
</script>

<style lang="scss">
.error {
  color: rgb(3, 1, 1);
  font-family: $font-family;
  font-size: $font-size-sm;
  margin-top: 5px;
}

.form-control {
  margin-top: 5px;
  background: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-sm;
  line-height: 20px;
  min-width: 230px;
  height: 51px !important;
  border: none;
  border-radius: 4px;
  color: $color-blue;
  font-family: $font-family;

  &--is-invalid {
    border-color: $color-red;
  }
}

.multiselect__tags {
  background: $color-light-grey !important;
  min-height: 50px;
  border: 1px solid $color-primary;
  padding: 13px 40px 0 8px;
}

.multiselect__select {
  min-height: 50px;
}

.multiselect__single {
  background: $color-light-grey !important;
  margin-bottom: 0;
}

.multiselect__option--highlight {
  background: $color-blue;

  &:after {
    background: $color-blue;
  }
}

.multiselect__input {
  background: $color-light-grey;
}
</style>
