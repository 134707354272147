import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useLocaleStore = defineStore("locale", () => {
  const loading = ref(false);
  const languages = ref([]);

  const request = async () => {
    try {
      loading.value = true;

      const { data } = await axios.get("languages");

      loading.value = false;
      languages.value = data.data;

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  return {
    loading,
    languages,

    request,
  };
});
