<template>
  <div>
    <Navigation />
    <div class="container">
      <div class="payment">
        <h1>Mokėjimas atšauktas</h1>
        <div class="payment__center">
          <CustomButton :type="'grey-transparent'" @click="goHome()">
            <template #text> Grįžti į pagrindinį puslapį </template>
          </CustomButton>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation.vue";
import CustomButton from "@/components/CustomButton.vue";
import { useRouter } from "vue-router";

export default {
  components: {
    Navigation,
    Footer,
    CustomButton,
  },
  setup() {
    const router = useRouter();

    return {
      router,
    };
  },
  methods: {
    goHome() {
      this.router.push({
        name: "home",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.payment {
  &__center {
    margin-top: 20px;
    text-align: center;
  }

  margin-top: 50px;
  margin-bottom: 50px;
  h1 {
    text-align: center;
    color: $color-black;
    font-family: $font-family;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
  }
}
</style>
