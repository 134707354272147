<template>
  <div class="course-time" :class="{ 'course-time--active': active }">
    <img v-if="!active" src="../../assets/clock.svg" />
    <img v-else src="../../assets/clock_black.svg" />
    {{ duration }}
  </div>
</template>
<script>
export default {
  props: {
    active: {},
    duration: {},
  },
};
</script>
<style lang="scss" scoped>
.course-time {
  display: flex;
  align-items: center;
  color: white;
  border-radius: 17px;
  background-color: rgba($color: #ffffff, $alpha: 0.1);
  padding: 9px 15px;
  gap: 10px;
  font-family: $font-family;
  font-size: $font-size-sm;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: uppercase;
  width: 100px;
  min-width: 100px;

  &--active {
    background-color: $color-light-blue;
    color: black;
  }
}
</style>
