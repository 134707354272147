<template>
  <label class="switch">
    <input type="checkbox" @click="toggleSwitchValue" />
    <span class="slidera round"></span>
  </label>
</template>

<script>
export default {
  name: "SwitchField",
  data() {
    return {
      switchValue: false,
    };
  },
  mounted() {
    if (this.inputValue !== "") {
      this.switchValue = this.inputValue;
    }
  },
  methods: {
    toggleSwitchValue() {
      this.switchValue = !this.switchValue;
      this.$emit("onChange", this.switchValue);
    },
  },
};
</script>

<style scoped lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slidera {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: #7F7F7F;
  transition: 0.4s;

  height: 20px;
  width: 50px;
  border-radius: 18px;
  background-color: #dcdee9;
}

.slidera:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slidera {
  background-color: $color-primary;
}

input:checked + .slidera:before {
  transform: translateX(30px);
}

.slidera:before {
  height: 26px;
  width: 26px;
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(45, 54, 113, 0.35);
  top: -4px;
  left: -3px;
}
</style>
