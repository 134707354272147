<template>
  <li class="menu-mobile__separator">
    <hr />
  </li>
</template>

<style lang="scss" scoped>
.menu-mobile__separator {
  & > hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    border-top: 1px solid $color-light-grey;
  }
}
</style>
