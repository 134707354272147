<template>
  <div class="tests">
    <h3>{{ $t("course.surveys") }}</h3>
    <div v-if="purchasedStore.surveys" class="tests__wrap">
      <CourseTest
        v-for="(test, index) in purchasedStore.surveys"
        :key="index"
        :test="test"
      />
    </div>
  </div>
</template>
<script>
import CourseTest from "@/components/course/CourseTest";
import { getPurchasedStore } from "@/store/modules/purchased.store";
import { useRoute } from "vue-router";
import { useAuth2Store } from "@/store/modules/auth2.store";

export default {
  components: {
    CourseTest,
  },
  setup() {
    const route = useRoute();
    const auth2Store = useAuth2Store();
    const purchasedStore = getPurchasedStore();

    return {
      route,
      auth2Store,
      purchasedStore,
    };
  },
  computed: {
    refreshToken() {
      return `${this.$i18n.locale}|${this.auth2Store.token}`;
    },
  },
  watch: {
    refreshToken: {
      immediate: true,
      async handler() {
        return Promise.all([
          this.purchasedStore.requestSurveys(this.route.params.id),
        ]);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.tests {
  margin-top: 60px;

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  h3 {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    gap: 116px;
    box-shadow: inset 0 -1px 0 0 #dcdee9;
    padding-top: 40px;
    padding-bottom: 40px;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__block {
    flex-basis: 100%;

    &--sidebar {
      flex-basis: 524px;
    }
  }
}
</style>
