<template>
  <div :style="{ 'flex-basis': width }" class="course">
    <router-link
      class="course__image"
      :to="{ name: ROUTE.Course.About, params: { id: course.product_id } }"
    >
      <img :src="course.thumb" />
    </router-link>

    <p class="course__content">{{ course.name }}</p>

    <div class="course__content-footer">
      <div class="course__rating-container">
        <div class="course__star">
          <img src="../../assets/star.svg" alt="star" />
        </div>
        <div class="course__rating">{{ course.rating }}/5</div>
        <!-- <div class="course__medal">
                    <img src="../../assets/medal.svg">
                </div> -->
      </div>
      <div v-if="course.date" class="course__date-available">
        {{ course.date }}
      </div>
      <div v-if="course.price != 0" class="course__price">
        {{ course.price_formated }}
      </div>
      <div v-else class="course__price-free">
        {{ $t("course.free") }}
      </div>
    </div>
  </div>
</template>

<script>
import { ROUTE } from "@/router/routenames";

export default {
  props: {
    course: {
      type: Object,
      default: () => ({
        title:
          "Communication with the patient in the provision, principles of communication",
        price: 19.9,
        rating: 4.8,
        free: false,
      }),
    },
    width: {
      type: String,
      default: "300px",
    },
  },
  data() {
    return {};
  },
  computed: {
    ROUTE() {
      return ROUTE;
    },
  },
};
</script>

<style lang="scss" scoped>
.course {
  flex-basis: 100%;
  max-width: 300px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 200px 1fr auto;

  &__date-available {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
  }

  img {
    width: 100%;
  }

  &__image {
    display: flex;
    align-items: center;
  }

  &__content {
    color: $color-black;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
    min-width: 40px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  &__content-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__rating-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__rating {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__price {
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__price-free {
    border-radius: 4px;
    background-color: $color-primary;
    color: #ffffff;
    font-family: $font-family;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: right;
    text-transform: uppercase;
    padding: 3px 7px;
  }
}
</style>
