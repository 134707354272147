<template>
  <div>
    <Navigation />

    <div class="discover-courses">
      <filter-modal-wrapper v-if="isMobile" />
      <div class="container">
        <div class="discover-courses__wrap">
          <div class="discover-courses__wrap__filter-wrapper">
            <h2>{{ $t("discover-courses.header") }}</h2>
            <filter-content v-if="!isMobile" />
          </div>
          <div class="discover-courses__block">
            <div
              v-if="globalStore.current_filters.length"
              class="discover-courses__header"
            >
              <div class="discover-courses__header-text">
                {{ $t("discover-courses.filter-by") }}
              </div>
              <div class="tags">
                <a
                  v-for="tag in globalStore.current_filters"
                  :key="tag"
                  class="tags__tag"
                  href="#"
                  @click.prevent
                >
                  <span
                    class="tags__close"
                    @click.prevent="onFilterRemove(tag.value)"
                  >
                    <img src="../../assets/tag_close.svg" />
                  </span>
                  <span class="tags__text">{{ tag.name }}</span>
                </a>
              </div>
            </div>
            <div
              v-if="productsStore.list.length === 0"
              class="discover-courses__courses-wrap"
            >
              <div class="discover-courses__empty">
                {{ $t("common.no_courses") }}
              </div>
            </div>
            <div v-else class="discover-courses__courses-wrap">
              <Loading v-if="productsStore.loading" />
              <Course
                v-for="course in productsStore.list"
                v-else
                :key="course.id"
                :best-seller="route.query.sort == 'popular'"
                :course="course"
              />
            </div>
            <div
              v-if="productsStore.list.length"
              class="discover-courses__footer"
            >
              <Pagination
                :page="productsStore.page"
                :page-count="productsStore.pageCount"
                :total="productsStore.total"
                :from="productsStore.from"
                :to="productsStore.to"
                @update="request"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  <StickyCartSlider v-if="isMobile" />
</template>
<script>
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation.vue";
import Course from "@/components/course/Course.vue";
import Pagination from "@/components/Pagination.vue";
import Loading from "@/components/Loading.vue";
import { useGlobalStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { getCurrentLanguageFilter } from "@/util/filters";
import { useProductsStore } from "@/store/modules/products.store";
import useIsMobile from "@/util/useIsMobile";
import FilterContent from "@/components/filter/FilterContent.vue";
import FilterModalWrapper from "@/components/filter/FilterModalWrapper.vue";
import StickyCartSlider from "@/components/cart/StickyCartSlider.vue";

export default {
  components: {
    StickyCartSlider,
    Loading,
    Navigation,
    Footer,
    Course,
    Pagination,
    FilterContent,
    FilterModalWrapper,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const globalStore = useGlobalStore();
    const productsStore = useProductsStore();
    const { isMobile } = useIsMobile();

    return {
      globalStore,
      route,
      router,
      productsStore,
      isMobile,
    };
  },
  data() {
    return {
      loading: false,
      filters: [],
      tags: [],
      courses: [],
      search: "",
      pagination: {
        total: 0,
        page: 0,
        limit: 0,
      },
    };
  },
  computed: {
    sort() {
      return this.route.query.sort || "default";
    },
    query() {
      return this.route.params;
    },
    category() {
      return this.route.query.category_id || "59";
    },
  },
  watch: {
    "route.query.q": {
      immediate: true,
      async handler() {
        this.request();
      },
    },
    "$i18n.locale": {
      immediate: true,
      async handler() {
        await this.globalStore.getFilters();
        await this.request();
      },
    },
  },
  async created() {
    this.globalStore.current_filters = [];
    if (this.route.query.filter_id) {
      this.globalStore.addFilterById(
        this.route.query.filter_id,
        this.route.query.filter_group_id,
      );
    }

    const { filter } = getCurrentLanguageFilter();
    this.globalStore.updateFilters(filter);
  },
  methods: {
    useIsMobile,
    isNumericArray(arr) {
      return arr.every(function (element) {
        return !isNaN(element);
      });
    },
    async onFilters(value) {
      const selectedFilterIndex = this.globalStore.current_filters.findIndex(
        (filter) => filter.value === value.filter_id,
      );
      if (selectedFilterIndex !== -1) {
        this.globalStore.current_filters.splice(selectedFilterIndex, 1);
      } else {
        this.globalStore.current_filters.push({
          name: value?.name,
          value: value.filter_id,
        });
      }

      this.request();
    },
    request(page = 1) {
      const { sort, category } = this;
      const { query: filters } = this.globalStore;
      const { q: search } = this.route.query;
      this.productsStore.request(page, 12, { sort, category, filters, search });
    },
    onFilterRemove(value) {
      this.globalStore.current_filters =
        this.globalStore.current_filters.filter(function (item) {
          return item.value !== value;
        });

      this.request();
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-label {
  color: $color-black;
  font-family: $font-family;
  font-size: $font-size-sm;
  letter-spacing: 0;
  line-height: 23px;

  padding-top: 20px;
  padding-bottom: 20px;
}

.tags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;

  a {
    text-decoration: none;
  }

  &__tag {
    border: 1px solid #dcdee9;
    border-radius: 18px;
    background-color: $color-white;
    height: 37px;
    min-width: 130px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    padding: 0px 3.5px;

    span {
    }
  }

  &__text {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-xs;
    letter-spacing: 0;
    line-height: 19px;
    text-decoration: none;
    padding: 10px;
  }

  &__close {
    height: 30px;
    width: 30px;
    background-color: $color-blue;
    border-radius: 30px;

    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.discover-courses {
  margin-top: 20px;

  &__empty {
    text-align: center;
    width: 100%;
    color: $color-black;
    font-family: $font-family;
  }

  &__show-more {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  h2 {
    @media screen and (max-width: $breakpoint-lg) {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    gap: 42px;
    margin-top: 30px;
    @media screen and (min-width: 900px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: $breakpoint-lg) {
      flex-wrap: wrap;
    }

    &__filter-wrapper {
      width: 260px;
      position: sticky;
      top: 85px;

      @media screen and (max-width: $breakpoint-lg) {
        width: 100%;
        position: static;
      }

      h2 {
        margin-bottom: 30px;

        @media screen and (max-width: $breakpoint-lg) {
          margin-bottom: 0;
        }
      }
    }

    .accordion__trigger {
      padding: 0 12px;
    }
  }

  &__block {
    width: 100%;
  }

  &__courses-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 20px;
    margin-top: 45px;
    margin-bottom: 60px;
    justify-content: flex-start;
    @media screen and (max-width: $breakpoint-lg) {
      justify-content: center;
      margin-top: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  &__header-text {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-xs;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__footer {
    margin-bottom: 50px;
  }

  &__footer-block {
    min-width: 105px;
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }
}
</style>
