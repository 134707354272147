<template>
  <div class="test">
    <div class="test">
      <div class="test__item" :class="{ 'test__item--done': test.completed }">
        <a href="#" class="test__name" @click.prevent="viewActivity">
          <div class="test__status">
            <img src="../../assets/correct_white.svg" alt="checkbox" />
          </div>
          <div class="test__title">{{ test.title }}</div>
        </a>
        <div class="test__description"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import router from "@/router";
import { ROUTE } from "@/router/routenames";

const props = defineProps({
  test: {
    type: Object,
  },
});

const viewActivity = () =>
  router.push({
    name: ROUTE.Course.Activity,
    query: {
      json: JSON.stringify({ ...props.test, backUrl: window.location.href }),
    },
  });
</script>
<style lang="scss" scoped>
.test {
  &__wrap {
  }

  &__item {
    height: 70px;
    display: flex;
    align-items: center;
    box-shadow: inset 0 -1px 0 0 #dcdee9;
    justify-content: space-between;

    &--done {
      .test__status {
        background: $color-green;
      }
    }
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
  }

  &__status {
    height: 24px;
    width: 24px;
    background-color: rgba($color-black, 0.4);
    border-radius: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__description {
    display: flex;
    gap: 10px;
  }
  &__video,
  &__algorithm {
    cursor: pointer;

    &:hover {
      background-color: darken($color-light-blue, 3);
    }
  }

  &__time,
  &__video,
  &__algorithm {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    border-radius: 17px;
    background-color: $color-light-blue;

    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 18px;

    padding: 0px 16px;
    gap: 10px;
  }
}
</style>
