<template>
  <transition name="fade" mode="out-in">
    <div class="full-page-modal-fade" :class="{ 'modal-fade__dark': dark }">
      <div class="modal-block">
        <a class="close" title="Uždaryti" @click="close">
          <img src="../assets/close_modal.svg" />
        </a>
        <div class="modal-header border-0 text-center">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    dark: {
      default: false,
    },
    width: {
      default: 500,
    },
    name: {
      default: "not_defined",
    },
    toggle: {
      default: false,
    },
  },
  data() {
    return {
      show: true,
    };
  },
  created() {
    this.show = true;
    let vm = this;
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
  },
};
</script>
<style lang="scss">
.full-page-modal-fade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 10000;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  &__dark {
    background-color: rgba(0, 0, 0, 0.8);

    .modal-block {
      background: black !important;
      border-radius: 0 !important;
      padding: 0 !important;
    }

    .close {
      background: white;
      border-radius: 30px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .modal-block {
    position: relative;
    padding: 20px;
    background-color: #ffffff;
    margin: 0 auto;
    width: 100%;
    height: 100%;

    .modal-header {
      padding: 0;
      text-align: center;

      h3 {
        color: $color-black;
        font-family: $font-family;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 32px;
        text-align: center;
      }
    }

    .modal-body {
      padding: 0;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  a.close {
    position: absolute;
    top: 15px;
    right: 20px;
    text-decoration: none;
    color: black;
    z-index: 99;
    cursor: pointer;
  }
}
</style>
