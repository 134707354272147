<template>
  <div :class="['fav', { dark, border, active: isFavourite }]" @click="toggle">
    <img :src="image" alt="Mark as favourite" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ROUTE } from "@/router/routenames";

const props = defineProps({
  data: Object,
  dark: {
    type: Boolean,
    default: false,
  },
  border: {
    type: Boolean,
    default: false,
  },
});

const FAV_COURSES = "favoriteCourses";

const route = useRoute();

const isFavourite = ref(false);

const isCurrentRouteCourse = computed(() => route.name === ROUTE.Course.Course);
const courseId = computed(() =>
  isCurrentRouteCourse.value
    ? window.location.href.split("/").at(-1)
    : props.data.id?.toString(),
);
const image = computed(() => {
  if (props.dark) {
    return isFavourite.value
      ? require("../../assets/course/fav_dark_filled.svg")
      : require("../../assets/course/fav_dark.svg");
  }

  return isFavourite.value
    ? require("../../assets/course/fav_white_filled.svg")
    : require("../../assets/course/fav_white.svg");
});

onMounted(() => {
  if (!props.data) {
    return;
  }

  const favs = localStorage.getItem(FAV_COURSES);
  if (!favs) {
    return;
  }

  const favKeys = Object.keys(JSON.parse(favs));

  isFavourite.value = favKeys.includes(courseId.value);
});

const toggle = () => {
  if (!props.data) {
    return;
  }

  const data = {
    id: courseId.value,
    title: isCurrentRouteCourse.value ? props.data.title : props.data.name,
    image: props.data.image,
    url: window.location.href,
  };

  const favs = localStorage.getItem(FAV_COURSES);

  if (!favs) {
    const obj = {
      [data.id]: data,
    };
    localStorage.setItem(FAV_COURSES, JSON.stringify(obj));
    isFavourite.value = true;
    return;
  }

  const favCourses = JSON.parse(favs);

  if (favCourses[data.id]) {
    delete favCourses[data.id];
    localStorage.setItem(FAV_COURSES, JSON.stringify(favCourses));
    isFavourite.value = false;
  } else {
    favCourses[data.id] = data;
    localStorage.setItem(FAV_COURSES, JSON.stringify(favCourses));
    isFavourite.value = true;
  }
};
</script>

<style scoped lang="scss">
.fav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 8px;
  cursor: pointer;

  &.border.dark {
    border: 2px solid $color-primary;
  }

  &.border {
    border: 2px solid #fff;
  }
}
</style>
