<template>
  <div class="menu-mobile__navbar">
    <button
      class="menu-mobile__navbar-close"
      type="button"
      @click="popupStore.closeMenu"
    >
      <img src="../../assets/x-1.svg" />
    </button>
    <div class="menu-mobile__navbar-label">Menu</div>
    <RouterLink
      v-if="auth2Store.isLoggedIn"
      :to="{ name: 'dashboard' }"
      class="menu-mobile__navbar-image"
    >
      <img :src="settingsStore.values.image" />
    </RouterLink>
    <RouterLink
      v-else
      :to="{ name: 'login' }"
      class="menu-mobile__navbar-image"
    >
      <img src="../../assets/menu-login.svg" />
    </RouterLink>
  </div>
</template>

<script setup>
import { useAuth2Store } from "@/store/modules/auth2.store";
import { usePopupStore } from "@/store/modules/popup.store";
import { useSettingsStore } from "@/store/modules/settings.store";

const popupStore = usePopupStore();
const auth2Store = useAuth2Store();
const settingsStore = useSettingsStore();
</script>

<style lang="scss" scoped>
.menu-mobile__navbar {
  display: flex;
  flex-direction: row;

  &-close {
    padding-top: 12px;
    padding-bottom: 18px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    background: transparent;
  }

  &-label {
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
    font-size: $font-size-lg;
    line-height: 15px;
    padding-top: 15px;
  }

  &-image {
    padding-top: 6px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    & > img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }
  }
}
</style>
