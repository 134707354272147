<template>
  <Form :initial-values="initialValues" @submit="handleSubmit">
    <TextInputField name="email" :placeholder="$t('common.your_email')" />
    <TextInputField
      name="firstname"
      :placeholder="$t('common.your_firstname')"
    />
    <TextInputField name="lastname" :placeholder="$t('common.your_lastname')" />
    <TextInputField
      name="telephone"
      :placeholder="$t('common.your_telephone')"
    />

    <div class="register__center">
      <CustomButton :type="'dark'" :full-width="true">
        <template #text>
          <Loading v-if="loading" :size="15" color="white" />
          <div v-else>{{ $t("common.change") }}</div>
        </template>
      </CustomButton>
    </div>
  </Form>
</template>

<script setup>
import { Form } from "vee-validate";
import TextInputField from "../TextInputField.vue";
import CustomButton from "@/components/CustomButton.vue";
import Loading from "@/components/Loading.vue";
import { defineEmits, defineProps } from "vue";

const emit = defineEmits(["submit"]);
defineProps({ initialValues: Object, loading: Boolean });

const handleSubmit = (values) => emit("submit", values);
</script>
