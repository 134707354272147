<template>
  <div class="course-item__block" @click="openCourse">
    <div class="course-item__left-text">
      <img :src="course.image" alt="Course" />
      <span
        class="course-item__left-text__course-title"
        v-text="course.title"
      />
    </div>
    <div class="course-item__right-text">
      <Progress
        :done="course.completed.done"
        :total="course.completed.total"
        :percent="percent"
        dark-layout
      />
      <div class="course-item__date">{{ date }}</div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Progress from "@/components/Progress.vue";
import { useRouter } from "vue-router";

export default {
  components: {
    Progress,
  },
  props: {
    course: {},
  },
  setup() {
    const router = useRouter();

    return {
      router,
    };
  },
  computed: {
    date() {
      return moment(this.course.date).locale("lt").format("ll");
    },
    percent() {
      return Math.round(
        (this.course.completed.done / this.course.completed.total) * 100,
      );
    },
  },
  methods: {
    async openCourse() {
      await this.router.push({
        name: "course.general",
        params: { id: this.course.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.course-item {
  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0 -1px 0 0 #dcdee9;
    cursor: pointer;
  }

  &__block:hover {
    background-color: $color-light-grey;
  }

  &__left-text {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 88px;
      height: 66px;
      object-fit: cover;
    }

    &__course-title {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-md;
      line-height: 22px;
      padding-right: 15px;
      word-break: break-word;
    }
  }

  &__right-text {
    display: flex;
    align-items: center;
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 18px;
    gap: 16px;
    padding: 22px 20px 22px 0;

    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
  }

  &__date {
    color: $color-blue;
    font-size: $font-size-xs;

    text-align: right;

    @media only screen and (max-width: $breakpoint-sm) {
      text-align: center;
    }
  }
}
</style>
