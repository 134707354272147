<template>
  <div class="activity">
    <GoBackButton @go:back="goBackToCourse" />
    <div class="activity__wrap">
      <iframe
        v-if="data && !error"
        :src="data.url"
        :style="iframeHeight ? { height: iframeHeight + 'px' } : {}"
      />
      <div v-else-if="error" class="activity__error">
        <div v-if="error === 404">
          <p>{{ $t("course.activity.not_found") }}</p>
          <CustomButton
            :type="'dark'"
            :text="$t('course.activity.back_to_course')"
            @click="goBackToCourse"
          />
        </div>
        <div v-else-if="error === 403">
          <p>{{ $t("course.activity.session_expired") }}</p>
          <CustomButton
            :type="'dark'"
            :text="$t('course.activity.session_refresh')"
            @click="refresh"
          />
        </div>
      </div>
      <GoBackButton @go:back="goBackToCourse" />
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { computed, onBeforeMount, onBeforeUnmount, ref } from "vue";
import router from "@/router";
import CustomButton from "@/components/CustomButton.vue";
import { lmsAxios } from "@/plugins/axios";
import { useAuth2Store } from "@/store/modules/auth2.store";
import GoBackButton from "@/components/navigation/GoBackButton.vue";

const route = useRoute();
const auth2Store = useAuth2Store();
const iframeHeight = ref(0);
const error = ref(null);

const IFRAME_MESSAGE = {
  ACTION: {
    SAVE: "saveQuiz",
    FINISH: "finish",
    IFRAME_HEIGHT: "documentSize",
  },
  MSG: {
    FORM_FINISHED: "formFinished",
  },
};

const data = computed(() => {
  if (undefined === route.query.json) {
    return {
      title: "",
      url: "",
      backUrl: "",
    };
  }

  const data = JSON.parse(route.query.json || {});

  const urlObj = new URL(data.url);
  urlObj.searchParams.set(
    "token",
    auth2Store.tokenPayload?.activity_token || urlObj.searchParams.get("token"),
  );
  data.url = urlObj.toString();

  return data;
});

const goBackToCourse = () =>
  data.value.backUrl
    ? (window.location.href = data.value.backUrl)
    : router.push({ name: "home" });

const refresh = () => window.location.reload();

const iframeEvent = (event) => {
  try {
    const data =
      typeof event.data === "object" ? event.data : JSON.parse(event.data);

    switch (data?.action || null) {
      case IFRAME_MESSAGE.ACTION.FINISH:
        if (data.message === IFRAME_MESSAGE.MSG.FORM_FINISHED) {
          goBackToCourse();
        }
        break;
      case IFRAME_MESSAGE.ACTION.IFRAME_HEIGHT:
        iframeHeight.value = data?.size || 0;
        break;
    }
  } catch (error) {
    console.error(error);
  }
};

onBeforeMount(async () => {
  await lmsAxios.get(data.value.url).catch(function (errorResponse) {
    error.value = errorResponse.response
      ? parseInt(errorResponse.response.status)
      : null;
  });

  window.addEventListener("message", iframeEvent, false);
});

onBeforeUnmount(() => window.removeEventListener("message", iframeEvent));
</script>

<style lang="scss" scoped>
.activity {
  margin-bottom: 60px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;

    iframe {
      position: relative;
      z-index: 1;
      border: 0;
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 40px;

    @media screen and (max-width: $breakpoint-lg) {
      margin-bottom: 20px;
    }
  }

  &__error {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-md;
    letter-spacing: 0;
    line-height: 40px;
    padding-bottom: 40px;
  }
}
</style>
