<template>
  <div class="settings__profile-image">
    <Loading v-if="loading" :size="106" color="black" />
    <img v-else :src="value" alt="" @click="triggerInput" />

    <input
      :id="id"
      ref="input"
      type="file"
      accept="image/*"
      @change="handleChange"
    />
  </div>
</template>

<script setup>
import { uniqid } from "@/plugins/misc";
import { defineEmits, defineProps, ref } from "vue";
import Loading from "../Loading.vue";

const id = uniqid("file-upload-");
const emit = defineEmits(["input"]);

defineProps({ value: String, loading: Boolean });

const input = ref(null);

const handleChange = (e) => {
  emit("input", e.target.files[0]);
};
const triggerInput = () => {
  input.value?.click();
};
</script>

<style lang="scss" scoped>
.spacer {
  padding: 10px;
}

input[type="file"] {
  display: none;
}

.settings {
  &__upload-photo {
    display: block;
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
    cursor: pointer;
  }

  &__profile-image {
    text-align: center;

    img {
      width: 106px;
      height: 106px;
      object-fit: cover;
      border-radius: 64px;
    }
  }
}
</style>
