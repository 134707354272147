<template>
  <div class="help">
    <div class="container">
      <div class="help__header">
        <h2>{{ $t("common.help") }}</h2>
      </div>

      <div class="help__wrap">
        <router-link :to="{ name: 'help' }" class="help__block">
          <div class="help__block-image">
            <img src="../assets/help_faq.svg" />
          </div>
          <h3 class="help__block-header">{{ $t("common.faq_short") }}</h3>
          <p class="help__block-description">{{ $t("common.faq") }}</p>
        </router-link>
        <router-link :to="{ name: 'help.offer' }" class="help__block">
          <div class="help__block-image">
            <img src="../assets/help_form.svg" />
          </div>
          <h3 class="help__block-header">{{ $t("common.request_form") }}</h3>
          <p class="help__block-description">
            {{
              $t("common.get_in_touch_easily_by_filling_out_the_contact_form")
            }}
          </p>
        </router-link>
        <router-link :to="{ name: 'help.contacts' }" class="help__block">
          <div class="help__block-image">
            <img src="../assets/help_contacts.svg" />
          </div>
          <h3 class="help__block-header">{{ $t("common.contacts") }}</h3>
          <p class="help__block-description">
            {{ $t("common.hybridlab_contacts_and_details") }}
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.help {
  &__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 21px;
    justify-content: center;

    margin-bottom: 80px;
  }

  &__block {
    flex-basis: 100%;
    max-width: 386px;
    border: 1px solid #dcdee9;
    text-align: center;
    padding: 30px;
    height: 210px;
    text-decoration: none;
  }

  &__header {
    h2 {
      color: $color-black;
      font-family: $font-family;
      font-size: 28px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 43px;
    }
    margin-bottom: 30px;
    margin-top: 50px;
  }

  &__block-image {
  }

  &__block-header {
    margin-top: 32px;
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-slg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
  }

  &__block-description {
    opacity: 0.5;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
  }
}
</style>
