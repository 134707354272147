<template>
  <li class="accordion__item" :class="{ 'accordion__item-pointer': !disabled }">
    <div
      class="accordion__trigger"
      :class="{ accordion__trigger_active: visible }"
      :style="{ '--accordion-trigger-padding': `${padding}px` }"
      @click="open"
    >
      <slot name="accordion-trigger" :is-open="visible" />
    </div>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-show="visible"
        class="accordion__content"
        :style="[
          `padding-left: ${padding}px;`,
          `margin-bottom: ${marginBottom}px;`,
        ]"
      >
        <div>
          <slot name="accordion-content" />
        </div>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  inject: ["Accordion", "padding", "hideOthers", "marginBottom"],
  props: {
    active: {
      default: () => {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Number,
      default: 24,
    },
  },
  data() {
    return {
      index: null,
      innerActive: false,
    };
  },
  computed: {
    visible() {
      if (this.innerActive) {
        return true;
      } else {
        if (this.hideOthers) {
          return this.index == this.Accordion.active;
        } else {
          return this.Accordion.list.includes(this.index);
        }
      }
    },
  },
  created() {
    this.innerActive = this.active;
    this.index = this.Accordion.count++;
  },
  methods: {
    open() {
      if (this.disabled) {
        return;
      }

      this.innerActive = false;

      if (this.hideOthers) {
        if (this.visible) {
          this.Accordion.active = null;
        } else {
          this.Accordion.active = this.index;
        }
      } else {
        if (this.Accordion.list.includes(this.index)) {
          let index = this.Accordion.list.findIndex((x) => x === this.index);
          this.Accordion.list.splice(index, 1);
        } else {
          this.Accordion.list.push(this.index);
        }
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion__content {
  color: $color-black;
  font-family: $font-family;
  font-size: $font-size-sm;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 27px;
}

.accordion__item {
  border-bottom: 1px solid #f1f5f9;
  position: relative;
}

.accordion__item-pointer {
  cursor: pointer;
}

.accordion__trigger {
  padding: 0 var(--accordion-trigger-padding);
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition:
    height 0.3s ease,
    opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
