<template>
  <div>
    <SimpleNavigation />
    <div class="auth-container">
      <div class="password-success">
        <img src="../../assets/email_sent.svg" alt="email svg" />

        <h2>{{ $t("common.reminder_sent") }}</h2>

        <p>{{ $t("commoon.forgot_password_content") }}</p>

        <CustomButton :type="'dark'" :full-width="true" :text="'Supratau'" />

        <a href="#">{{ $t("common.try_again") }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleNavigation from "@/components/SimpleNavigation";
import CustomButton from "@/components/CustomButton.vue";

export default {
  components: {
    SimpleNavigation,
    CustomButton,
  },
};
</script>
<style lang="scss" scoped>
.password-success {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.auth-container {
  max-width: 399px;
  margin: 0 auto;

  img {
    margin-bottom: 60px;
  }

  h2 {
    color: $color-black;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    text-align: center;

    margin-bottom: 30px;
  }

  p {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;

    margin-bottom: 60px;
  }

  button {
    margin-bottom: 67px;
  }

  a {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
    text-decoration: none;
  }
}
</style>
