<template>
  <div>
    <SimpleNavigation />

    <div class="auth-container">
      <div class="register">
        <h2>{{ $t("common.register") }}</h2>

        <div class="register__wrap">
          <div class="register__block">
            <Form :initial-values="{ agree: 0 }" @submit="auth2Store.register">
              <TextField
                rules="required"
                name="username"
                :label="$t('common.username')"
                :placeholder="$t('common.username')"
              />
              <TextField
                rules="required"
                name="firstname"
                :label="$t('common.first_name')"
                :placeholder="$t('common.first_name')"
              />
              <TextField
                rules="required"
                name="lastname"
                :label="$t('common.last_name')"
                :placeholder="$t('common.last_name')"
              />
              <TextField
                rules="required|email"
                name="email"
                :label="$t('common.register_email')"
                :placeholder="$t('common.register_email')"
              />
              <TextField
                rules="required|hlphone"
                name="phone"
                :label="$t('common.phone_number')"
                :placeholder="$t('common.phone_number')"
              />
              <PasswordField
                rules="required"
                name="password"
                :label="$t('common.create_pass')"
                :placeholder="$t('common.create_pass')"
              />
              <PasswordField
                rules="required|confirmed:@password"
                name="confirm"
                :label="$t('common.repeat_pass')"
                :placeholder="$t('common.repeat_pass')"
              />
              <DateField
                rules="required|hlbirthdate"
                name="birthdate"
                :label="$t('common.birthday_date')"
                :placeholder="$t('common.birthday_date')"
                :max="now()"
              />

              <CheckboxField rules="required" :name="'agree'" :value="0">
                <div>
                  {{ $t("common.i_have_met_and_agree_with") }}
                  <router-link :to="{ name: 'home' }">
                    {{ $t("common.terms_of_use") }}
                  </router-link>
                  {{ $t("common.and") }}
                  <router-link :to="{ name: 'home' }">
                    {{ $t("common.privacy_policy") }}
                  </router-link>
                </div>
              </CheckboxField>

              <div class="register__center">
                <CustomButton
                  :type="'dark'"
                  :disabled="auth2Store.loading"
                  full-width
                >
                  <template #text>
                    {{ $t("common.continue") }}
                  </template>
                </CustomButton>
              </div>
            </Form>

            <p>
              {{ $t("common.you_already_have_account") }}
              <router-link :to="{ name: 'login' }"
                >{{ $t("common.login") }}
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleNavigation from "@/components/SimpleNavigation";
import TextField from "@/components/form/TextField";
import PasswordField from "@/components/form/PasswordField";
import CheckboxField from "@/components/form/CheckboxField";
import CustomButton from "@/components/CustomButton.vue";
import { Form } from "vee-validate";
import { useAlertStore, useCartStore } from "@/store";
import { useRouter } from "vue-router";
import DateField from "@/components/form/DateField.vue";
import { useAuth2Store } from "@/store/modules/auth2.store";
import i18n from "@/i18n";
import { now } from "@/util/helpers";
import { ref } from "vue";

export default {
  components: {
    DateField,
    SimpleNavigation,
    TextField,
    PasswordField,
    CustomButton,
    CheckboxField,
    Form,
  },
  setup() {
    const alertStore = useAlertStore();
    const router = useRouter();
    const auth2Store = useAuth2Store();
    const cartStore = useCartStore();

    const agree = ref(false);

    return {
      agree,
      alertStore,
      auth2Store,
      router,
      cartStore,
    };
  },
  watch: {
    "auth2Store.token": {
      immediate: true,
      handler(value) {
        if (!value) return;

        this.alertStore.success(
          "Success",
          i18n.global.t("common.user_register_success"),
        );

        this.cartStore.getItems();
        setTimeout(() => {
          if (this.$route.query.redirect) {
            this.$router.replace(this.$route.query.redirect);
          } else {
            this.$router.replace({ name: "home" });
          }
        }, 3000);
      },
    },
  },
  methods: { now },
};
</script>
<style lang="scss" scoped>
.auth-container {
  max-width: 399px;
  margin: 0 auto;
  padding: 15px;
}

.register {
  h2 {
    color: $color-black;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    text-align: center;
    margin-bottom: 40px;
  }

  &__socials {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      text-align: center;

      color: $color-black;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;
      text-align: center;

      a {
        color: $color-blue;
      }
    }

    &--divider {
      padding: 40px 0px;

      h2 {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #dcdee9;
        line-height: 1px;
        margin: 10px 0 20px;

        opacity: 0.5;
        color: $color-blue;
        font-family: $font-family;
        font-size: 13px;
        letter-spacing: 0;
        text-align: center;
      }

      span {
        background-color: white;
        padding: 0 10px;
      }
    }
  }

  &__facebook {
    position: relative;
    border-radius: 4px;
    background-color: #1360c2;
    height: 52px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;

    width: 100%;

    img {
      position: absolute;
      left: 20px;
    }
  }

  &__google {
    position: relative;
    border-radius: 4px;
    background-color: #c7392d;
    height: 52px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;

    width: 100%;

    img {
      position: absolute;
      left: 20px;
    }
  }
}
</style>
