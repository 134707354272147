<template>
  <div>
    <Navigation />
    <div class="container">
      <div class="faqs">
        <h2>Dažniausiai užduodami klausimai</h2>
        <div class="faqs__wrap">
          <router-link :to="{ name: 'faq-category' }" class="faqs__block">
            <div class="faqs__image">
              <img src="../../assets/faq/0.svg" alt="icon" />
            </div>
            <div class="faqs__text">
              <h4>Mokymai karantino metu</h4>
              <p>
                At vero eos censes aut reiciendis voluptatibus maiores alias
                consequatur.
              </p>
            </div>
          </router-link>

          <router-link :to="{ name: 'faq-category' }" class="faqs__block">
            <div class="faqs__image">
              <img src="../../assets/faq/1.svg" alt="icon" />
            </div>
            <div class="faqs__text">
              <h4>Registracija</h4>
              <p>
                At vero eos censes aut reiciendis voluptatibus maiores alias
                consequatur.
              </p>
            </div>
          </router-link>
          <router-link :to="{ name: 'faq-category' }" class="faqs__block">
            <div class="faqs__image">
              <img src="../../assets/faq/2.svg" alt="icon" />
            </div>
            <div class="faqs__text">
              <h4>Nuotolinis mokymasis</h4>
              <p>
                At vero eos censes aut reiciendis voluptatibus maiores alias
                consequatur.
              </p>
            </div>
          </router-link>
          <router-link :to="{ name: 'faq-category' }" class="faqs__block">
            <div class="faqs__image">
              <img src="../../assets/faq/3.svg" alt="icon" />
            </div>
            <div class="faqs__text">
              <h4>Mokymasis laboratorijoje</h4>
              <p>
                At vero eos censes aut reiciendis voluptatibus maiores alias
                consequatur.
              </p>
            </div>
          </router-link>
          <router-link :to="{ name: 'faq-category' }" class="faqs__block">
            <div class="faqs__image">
              <img src="../../assets/faq/4.svg" alt="icon" />
            </div>
            <div class="faqs__text">
              <h4>Mokėjimai ir prenumeratos</h4>
              <p>
                At vero eos censes aut reiciendis voluptatibus maiores alias
                consequatur.
              </p>
            </div>
          </router-link>
          <router-link :to="{ name: 'faq-category' }" class="faqs__block">
            <div class="faqs__image">
              <img src="../../assets/faq/5.svg" alt="icon" />
            </div>
            <div class="faqs__text">
              <h4>Sertifikatai</h4>
              <p>
                At vero eos censes aut reiciendis voluptatibus maiores alias
                consequatur.
              </p>
            </div>
          </router-link>
          <router-link :to="{ name: 'faq-category' }" class="faqs__block">
            <div class="faqs__image">
              <img src="../../assets/faq/6.svg" alt="icon" />
            </div>
            <div class="faqs__text">
              <h4>Mokymai įstaigoms ir įmonėms</h4>
              <p>
                At vero eos censes aut reiciendis voluptatibus maiores alias
                consequatur.
              </p>
            </div>
          </router-link>
          <router-link :to="{ name: 'faq-category' }" class="faqs__block">
            <div class="faqs__image">
              <img src="../../assets/faq/7.svg" alt="icon" />
            </div>
            <div class="faqs__text">
              <h4>Galimybės verslui</h4>
              <p>
                At vero eos censes aut reiciendis voluptatibus maiores alias
                consequatur.
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";

export default {
  components: {
    Navigation,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
.faqs {
  margin-bottom: 80px;
  h2 {
    color: $color-blue;
    font-family: $font-family;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    margin-top: 38px;
    margin-bottom: 40px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px 11px;
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 30px;
    border: 1px solid #dcdee9;
    min-width: 571px;
    text-decoration: none;

    @media screen and (max-width: 900px) {
      min-width: initial;
      width: 100%;
    }

    img {
      padding: 24px 0px 24px 31.5px;
      height: 80px;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 73px;
  }

  &__text {
    padding: 0px 21px 0px 0px;
    h4 {
      color: $color-black;
      font-family: $font-family;
      font-size: $font-size-md;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 29px;
    }

    p {
      opacity: 0.5;
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
}
</style>
