<template>
  <Form :initial-values="initialValues" @submit="handleSubmit">
    <SelectInputField
      name="gender"
      :options="genders"
      :placeholder="$t('common.your_gender')"
    />
    <TextInputField
      name="date_of_birth"
      :placeholder="$t('common.your_birthdate')"
    />

    <div class="register__center">
      <CustomButton :type="'dark'" :full-width="true">
        <template #text>
          <Loading v-if="loading" :size="15" color="white" />
          <div v-else>{{ $t("common.change") }}</div>
        </template>
      </CustomButton>
    </div>
  </Form>
</template>
<script setup>
import { Form } from "vee-validate";
import SelectInputField from "../SelectInputField.vue";
import CustomButton from "@/components/CustomButton.vue";
import Loading from "@/components/Loading.vue";
import { defineEmits, defineProps } from "vue";
import TextInputField from "../TextInputField.vue";

const emit = defineEmits(["submit"]);
defineProps({ initialValues: Object, loading: Boolean });

const handleSubmit = (values) => emit("submit", values);

const genders = [
  {
    name: "Vyras",
    value: "male",
  },
  {
    name: "Moteris",
    value: "female",
  },
];
</script>
