<template>
  <Field
    v-slot="{ componentField, handleInput, errors }"
    :name="name"
    :rules="rules"
    :label="label || placeholder"
    @update:model-value="emitInput"
  >
    <SelectInput
      v-bind="componentField"
      :name="name"
      :options="options"
      :label="label"
      :placeholder="placeholder"
      :errors="errors"
      @model-value:update="handleInput"
    />
  </Field>
</template>

<script setup>
import { Field } from "vee-validate";
import SelectInput from "@/components/inputs/SelectInput.vue";
import { defineEmits, defineProps } from "vue";

const emit = defineEmits(["input"]);
defineProps({
  name: String,
  options: Array,
  label: String,
  placeholder: String,
  rules: {
    type: [Object, String],
    default: "",
  },
});

const emitInput = (value) => emit("input", value);
</script>
