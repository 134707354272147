<template>
  <div>
    <!-- <SimpleNavigation :auth="true"/> -->
    <Navigation />

    <HeaderBlock />
    <div class="bg-grey">
      <div class="container">
        <div class="certificates">
          <div class="certificates__header">
            <div class="certificates__column certificates__column--switch">
              <h3>{{ $t("common.my_certificates") }}</h3>
              <div class="certificates__settings">
                <div class="certificates__settings-label">
                  {{ $t("common.show_valid_certificates") }}
                </div>
                <SwitchField @change="validCertificates()" />
              </div>
            </div>
            <div class="certificates__header-column"></div>
          </div>

          <div class="certificates__wrap">
            <Loading v-if="loading" />
            <CertificateItem
              v-for="certificate in filtered"
              :key="certificate.id"
              :certificate="certificate"
            />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import SimpleNavigation from "@/components/SimpleNavigation";
import HeaderBlock from "@/components/dashboard/HeaderBlock";
import CertificateItem from "@/components/dashboard/CertificateItem";
import SwitchField from "@/components/form/SwitchField";
import Footer from "@/components/Footer";
import Loading from "@/components/Loading";
import Navigation from "@/components/Navigation.vue";
import backService from "@/plugins/service";
import moment from "moment";

export default {
  components: {
    SimpleNavigation,
    HeaderBlock,
    CertificateItem,
    SwitchField,
    Footer,
    Navigation,
    Loading,
  },
  data() {
    return {
      valid: false,
      loading: false,
      certificates: [],
      filtered: [],
    };
  },
  created() {
    this.getSertificates();
  },
  methods: {
    validCertificates() {
      this.valid = !this.valid;
      if (this.valid) {
        this.filtered = this.certificates.filter((certificate) => {
          const endDate = moment(certificate.valid.to),
            todayDate = moment();

          const difference = todayDate.diff(endDate, "days");

          return difference <= 0;
        });
      } else {
        this.filtered = this.certificates;
      }
    },
    async getSertificates() {
      let vm = this;
      this.loading = true;
      await backService
        .get("/certificates")
        .then((response) => {
          vm.loading = false;
          vm.certificates = response.data;
          vm.filtered = response.data;
        })
        .catch(() => {
          vm.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.certificates {
  padding-top: 60px;
  padding-bottom: 80px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 27px;

    h3 {
      color: $color-black;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__settings-label {
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__column {
    display: flex;
    align-items: center;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &--switch {
      gap: 40px;
      @media screen and (max-width: 900px) {
        gap: 10px;
      }
    }
  }

  &__wrap {
    overflow-x: auto;
  }
}
</style>
