import backService from "@/plugins/service";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useSettingsStore } from "./settings.store";
import { useAccountStore } from "./account.store";
import router from "@/router";
import { useGuestStore } from "./guest.store";
import { useAlertStore } from "@/store";
import { findStrTranslation } from "@/util/helpers";
import i18n from "@/i18n";
import { ROUTE } from "@/router/routenames";

const STORAGE_TOKEN = "access-token";
const STORAGE_REFRESH_TOKEN = "refresh-token";

export const useAuth2Store = defineStore("auth2", () => {
  const settingsStore = useSettingsStore();

  const loading = ref(false);
  const token = ref(localStorage.getItem(STORAGE_TOKEN));
  const refreshToken = ref(localStorage.getItem(STORAGE_REFRESH_TOKEN));
  const logoutUrl = ref(null);

  const tokenPayload = computed(() => {
    if (!token.value) {
      return null;
    }

    return JSON.parse(atob(`${token.value}`.split(".")[1]));
  });

  const ocSessionId = computed(() => {
    if (!tokenPayload.value) return null;

    return tokenPayload.value["X-Oc-Session-Id"];
  });

  const sessionEndsAt = computed(() => {
    if (!tokenPayload.value) return null;

    return +tokenPayload.value.exp;
  });

  const isLoggedIn = computed(() => !!token.value);

  const callback = async (data) => {
    localStorage.setItem(STORAGE_TOKEN, data.token);
    localStorage.setItem(STORAGE_REFRESH_TOKEN, data.refresh_token);

    token.value = data.token;
    refreshToken.value = data.refresh_token;
  };

  const refresh = async () => {
    if (!sessionEndsAt.value) return true;

    const timeLeft = sessionEndsAt.value * 1000 - Date.now();
    if (timeLeft > 30000) return true;

    try {
      const { data } = await backService.post("auth/refresh", {
        token: token.value,
        refresh_token: refreshToken.value,
      });

      await callback(data);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const login = async ({ username, password }) => {
    const guestStore = useGuestStore();
    const alertStore = useAlertStore();
    try {
      loading.value = true;
      const { data } = await backService.post("auth/login", {
        username,
        password,
        oc_session: guestStore.session,
      });

      await callback(data);

      loading.value = false;

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;
      alertStore.error(
        i18n.global.t("common.error"),
        i18n.global.t("common.incorrect_login"),
      );

      return false;
    }
  };

  const logout = async () => {
    logoutUrl.value = settingsStore.values.logout_iframe_url;
    const isSSOUrl = !!process.env.VUE_APP_SSO_URL;

    token.value = null;
    refreshToken.value = null;

    localStorage.removeItem(STORAGE_TOKEN);
    localStorage.removeItem(STORAGE_REFRESH_TOKEN);

    useGuestStore().reset();
    useSettingsStore().reset();
    useAccountStore().reset();

    const redirectRoute = isSSOUrl ? ROUTE.Home : ROUTE.Login;
    router.push({ name: redirectRoute });

    return true;
  };

  const unsetLogoutUrl = () => (logoutUrl.value = null);

  const ssoLogin = async (accessToken) => {
    const { data } = await backService.post("auth/sso", {
      access_token: accessToken,
      oc_session: useGuestStore().session,
    });

    await useAuth2Store().callback(data);
  };

  const register = async ({
    username,
    password,
    firstname,
    lastname,
    email,
    birthdate,
    phone,
  }) => {
    try {
      loading.value = true;

      const guestStore = useGuestStore();
      const { data } = await backService.post("auth/register", {
        username,
        password,
        firstname,
        lastname,
        email,
        birthdate,
        phone,
        oc_session: guestStore.session,
      });

      await callback(data);

      loading.value = false;

      return true;
    } catch (error) {
      console.error(error);

      loading.value = false;

      const alertStore = useAlertStore();
      if (error.response.status === 500) {
        alertStore.error(
          i18n.global.t("common.error"),
          "Server error, please try again later.",
        );
      } else if (
        error.response?.data &&
        Object.keys(error.response?.data).length
      ) {
        Object.values(error.response?.data).forEach((arr) => {
          alertStore.error(
            i18n.global.t("common.error") +
              ": " +
              error.response?.status?.toString(),
            findStrTranslation(
              arr[0]?.toString(),
              "en",
              "common.register_error",
            ),
          );
        });
      } else {
        alertStore.error(
          i18n.global.t("common.error") +
            ": " +
            error.response?.status?.toString(),
          "Couldn't register user",
        );
      }

      return false;
    }
  };

  return {
    loading, //
    token,
    tokenPayload,
    refreshToken,
    logoutUrl,
    isLoggedIn,
    ocSessionId,

    login,
    ssoLogin,
    logout,
    unsetLogoutUrl,
    refresh,
    register,
    callback,
  };
});
