<template>
  <div>
    <SimpleNavigation />
    <div class="auth-container">
      <div class="password-reset">
        <h2>{{ $t("common.enter_password") }}</h2>
        <div class="password-reset__wrap">
          <div class="password-reset__block">
            <Form @submit="reset">
              <TextField
                name="password"
                :rules="'required|min:8|max:20'"
                :label="$t('common.enter_password')"
                :placeholder="$t('common.create_information')"
                :type="'password'"
              />
              <TextField
                name="password_repeat"
                :rules="'required|min:8|max:20'"
                :label="$t('common.repeat_new_password')"
                :placeholder="$t('common.repeat_password')"
                :type="'password'"
              />

              <div class="password-reset__center">
                <CustomButton
                  :type="'dark'"
                  :disabled="isLoading"
                  :full-width="true"
                  :text="$t('common.save_information')"
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import SimpleNavigation from "@/components/SimpleNavigation";
import TextField from "@/components/form/TextField";
import CustomButton from "@/components/CustomButton.vue";
import axios from "axios";
import { ref } from "vue";
import { useAlertStore } from "@/store";
import { Form } from "vee-validate";
import router from "@/router";
import { ROUTE } from "@/router/routenames";

const props = defineProps({
  token: {
    type: String,
    required: true,
  },
});

const isLoading = ref(false);
const alertStore = useAlertStore();

const reset = async (values, actions) => {
  console.log("DATA", values, actions);

  if (values.password !== values.password_repeat) {
    alertStore.error(undefined, "Passwords must match");
    return;
  }

  isLoading.value = true;

  await axios
    .post(process.env.VUE_APP_SERVICE_URL + "/auth/password/reset", {
      token: props.token,
      ...values,
    })
    .then(async () => {
      isLoading.value = false;

      alertStore.success("Success", "Password were successfully updated");

      setTimeout(() => {
        router.push({
          name: ROUTE.Login,
        });
      }, 2000);
    })
    .catch((error) => {
      isLoading.value = false;

      alertStore.error("Error", error);
    });
};
</script>
<style lang="scss" scoped>
.auth-container {
  max-width: 399px;
  margin: 0 auto;
}

.password-reset {
  h2 {
    color: $color-black;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    text-align: center;
    margin-bottom: 40px;
  }

  &__socials {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      text-align: center;

      color: $color-black;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;
      text-align: center;

      a {
        color: $color-blue;
      }
    }

    &--divider {
      padding: 40px 0px;

      h2 {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #dcdee9;
        line-height: 1px;
        margin: 10px 0 20px;

        opacity: 0.5;
        color: $color-blue;
        font-family: $font-family;
        font-size: 13px;
        letter-spacing: 0;
        text-align: center;
      }

      span {
        background-color: white;
        padding: 0 10px;
      }
    }
  }

  &__facebook {
    position: relative;
    border-radius: 4px;
    background-color: #1360c2;
    height: 52px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;

    width: 100%;

    img {
      position: absolute;
      left: 20px;
    }
  }

  &__google {
    position: relative;
    border-radius: 4px;
    background-color: #c7392d;
    height: 52px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;

    width: 100%;

    img {
      position: absolute;
      left: 20px;
    }
  }
}
</style>
