<template>
  <div>
    <Navigation />
    <HeaderBlock />
    <div class="bg-grey">
      <div class="container">
        <div class="courses">
          <div class="courses__header">
            <h3>{{ $t("common.purchases_history") }}</h3>
          </div>

          <loading v-if="loading" />
          <div>
            <div v-if="orders.length !== 0" class="courses__wrap">
              <router-link
                v-for="order in orders"
                :key="order.order_id"
                :to="{
                  name: 'dashboard.purchases.show',
                  params: { id: order.order_id },
                }"
              >
                <div class="course">
                  <div class="course__content">
                    <div class="course__id">ID: {{ order.order_id }}</div>
                    <div class="course__name">
                      <div
                        v-for="product in order.products"
                        :key="product.product_id"
                        class="course__item"
                      >
                        {{ product.name }} {{ product.quantity }} x
                        {{ product.price }} €
                      </div>
                    </div>
                    <div class="course__date">{{ order.date_added }}</div>
                    <div class="course__price">{{ order.total }}</div>
                    <div class="course__arrow"></div>
                  </div>
                </div>
              </router-link>
            </div>
            <div v-else class="course__wrap">
              <div class="course__error">
                {{ $t("common.purchases_history_empty") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderBlock from "@/components/dashboard/HeaderBlock";
import Footer from "@/components/Footer";
import Loading from "@/components/Loading.vue";
import Navigation from "@/components/Navigation.vue";
import axios from "axios";

export default {
  components: {
    HeaderBlock,
    Footer,
    Navigation,
    Loading,
  },
  data() {
    return {
      loading: false,
      orders: [],
    };
  },
  created() {
    this.getPurchasedCourses();
  },
  methods: {
    async getPurchasedCourses() {
      this.loading = true;
      let vm = this;
      await axios
        .get("/customerorders")
        .then((response) => {
          vm.orders = response.data.data;
          vm.loading = false;
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.courses {
  padding-top: 60px;
  padding-bottom: 80px;

  &__header {
    margin-bottom: 27px;

    h3 {
      color: $color-black;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__wrap {
    overflow-x: auto;

    a {
      text-decoration: none;
    }
  }
}

.course {
  padding: 13px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 #dcdee9;
  text-decoration: none;
  min-width: 800px;
  max-width: 100%;

  &__error {
    color: $color-black;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
    flex-basis: 20%;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
  }

  &__id {
    color: $color-black;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
    flex-basis: 20%;
  }

  &__name {
    color: $color-black;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
    flex-basis: 40%;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 400px;
  }

  &__date {
    opacity: 0.5;
    color: $color-blue;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
    flex-basis: 20%;
  }

  &__price {
    color: $color-black;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
    flex-basis: 10%;
  }
}
</style>
